import { EMAIL, phoneNumber } from '@utils/appConfig';

import colors from '@theme/configs/colors';

import Box from '@atoms/Box';
import Mail from '@atoms/Icons/MailEnvelope';
import Phone from '@atoms/Icons/Phone';
import Typography from '@atoms/Typography';

const Contacts = () => (
    <div>
        <Box display="flex" alignItems="center" mb="1.25rem">
            <Mail color={colors.brandAccent} />
            <a href={`mailto:${EMAIL}`}>
                <Typography color="var(--white)" variant="paragraph2" ml="1.25rem">
                    {EMAIL}
                </Typography>
            </a>
        </Box>
        <Box display="flex" alignItems="center">
            <Phone color={colors.brandAccent} />
            <a href={`tel:${phoneNumber}`}>
                <Typography color="var(--white)" variant="paragraph2" ml="1.25rem">
                    {phoneNumber}
                </Typography>
            </a>
        </Box>
    </div>
);

export default Contacts;
