import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={31}
        height={31}
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M28.09 20.1801C26.2732 20.1786 24.4678 19.8918 22.74 19.3301C22.3343 19.197 21.9008 19.1731 21.483 19.2609C21.0652 19.3487 20.6778 19.545 20.36 19.8301L16.99 22.4201C13.3607 20.6109 10.4191 17.6694 8.61001 14.0401L11.08 10.7601C11.3955 10.4442 11.6183 10.0477 11.7241 9.61397C11.8299 9.18021 11.8146 8.72576 11.68 8.30007C11.1103 6.57047 10.82 4.76107 10.82 2.94005C10.82 2.62404 10.7576 2.31114 10.6364 2.01931C10.5151 1.72747 10.3375 1.46245 10.1135 1.23946C9.88963 1.01647 9.6239 0.839891 9.33156 0.71987C9.03923 0.599848 8.72607 0.538743 8.41006 0.54006H2.90005C2.5845 0.538737 2.27184 0.59991 1.98006 0.720053C1.68828 0.840195 1.42316 1.01695 1.20003 1.24007C0.976909 1.4632 0.800156 1.72829 0.680014 2.02007C0.559871 2.31185 0.498698 2.62451 0.500021 2.94005C0.507957 10.2559 3.41701 17.2699 8.58913 22.4439C13.7613 27.6179 20.7742 30.5295 28.09 30.5401C28.7284 30.5374 29.3399 30.2827 29.7913 29.8313C30.2427 29.3799 30.4974 28.7684 30.5 28.1301V22.6401C30.5053 22.3198 30.447 22.0016 30.3284 21.704C30.2098 21.4064 30.0332 21.1354 29.809 20.9065C29.5849 20.6777 29.3174 20.4956 29.0224 20.3709C28.7273 20.2462 28.4104 20.1814 28.09 20.1801Z"
            fill="#4052F8"
        />
    </svg>
);

const Memo = memo(SvgPhone);
export default Memo;
