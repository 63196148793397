import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@atoms/Box';
import GridChild from '@atoms/GridChild';
import GridParent from '@atoms/GridParent';

import Slider from '@molecules/Slider';

import Card from './parts/Card';

const query = graphql`
    query {
        methodsBg: file(relativePath: { eq: "methods_card.jpeg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        beliefsBg: file(relativePath: { eq: "beliefs_card.jpeg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        evolutionBg: file(relativePath: { eq: "evolution_card.jpeg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;

const ProsCards = (): ReactElement => {
    const { t } = useTranslation();
    const { tablet } = useBreakpoint();
    const [maxHeight, setMaxHeight] = useState(0);
    const { methodsBg, beliefsBg, evolutionBg } = useStaticQuery(query);

    return (
        <section id="additional-pros-info">
            <Box overflowX="hidden">
                <GridParent
                    gridRowGap={{ _: '3rem', desktop: undefined }}
                    gridColumnGap={{ _: undefined, desktop: '3rem' }}
                    mt={{ _: '12rem', tablet: '18rem' }}
                >
                    <GridChild gridColumn={{ _: '1/13', desktop: '3/11' }}>
                        {tablet ? (
                            <Box
                                display="flex"
                                height="100%"
                                gridGap="3rem"
                                flexDirection={{ _: 'column', laptopS: 'row' }}
                            >
                                <Card
                                    maxHeight={maxHeight}
                                    setMaxHeight={setMaxHeight}
                                    image={methodsBg.childImageSharp.gatsbyImageData}
                                    heading={t('points.methods.heading')}
                                    caption={t('points.methods.description')}
                                />
                                <Card
                                    maxHeight={maxHeight}
                                    setMaxHeight={setMaxHeight}
                                    image={beliefsBg.childImageSharp.gatsbyImageData}
                                    heading={t('points.specialists.heading')}
                                    caption={t('points.specialists.description')}
                                    caption2={t('points.specialists.description2')}
                                />
                                <Card
                                    maxHeight={maxHeight}
                                    setMaxHeight={setMaxHeight}
                                    image={evolutionBg.childImageSharp.gatsbyImageData}
                                    heading={t('points.solutions.heading')}
                                    caption={t('points.solutions.description')}
                                />
                            </Box>
                        ) : (
                            <Slider slidesPerView={1.2} showOverflow spaceBetween={10}>
                                <Card
                                    maxHeight={maxHeight}
                                    setMaxHeight={setMaxHeight}
                                    image={methodsBg.childImageSharp.gatsbyImageData}
                                    heading={t('points.methods.heading')}
                                    caption={t('points.methods.description')}
                                />
                                <Card
                                    maxHeight={maxHeight}
                                    setMaxHeight={setMaxHeight}
                                    image={beliefsBg.childImageSharp.gatsbyImageData}
                                    heading={t('points.specialists.heading')}
                                    caption={t('points.specialists.description')}
                                    caption2={t('points.specialists.description2')}
                                />
                                <Card
                                    maxHeight={maxHeight}
                                    setMaxHeight={setMaxHeight}
                                    image={evolutionBg.childImageSharp.gatsbyImageData}
                                    heading={t('points.solutions.heading')}
                                    caption={t('points.solutions.description')}
                                />
                            </Slider>
                        )}
                    </GridChild>
                </GridParent>
            </Box>
        </section>
    );
};

export default ProsCards;
