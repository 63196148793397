import { FormEvent, ForwardedRef, forwardRef, useState } from 'react';
import styled from 'styled-components';

import Box from '@atoms/Box';
import FloatingLabel from '@atoms/Form/FloatingLabel';
import { InputProps } from '@atoms/Form/Input';
import Typography, { fontsConfig } from '@atoms/Typography';

const StyledTextArea = styled.textarea<{ hasError: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.grey};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.brandBlack};
    ${{ ...fontsConfig.paragraph2 }};
    padding: 2rem 1.25rem 0.8rem 2rem;
    width: 100%;

    &:focus {
        border-color: ${({ theme }) => theme.colors.brandAccent};
    }

    ${({ hasError, theme }) =>
        hasError &&
        `
    border-color: ${theme.colors.redWarning};
  `};
`;

const TextArea = (
    {
        onChange,
        value,
        name,
        label,
        error,
        ...rest
    }: Omit<InputProps, 'placeholder' | 'type'> & Pick<HTMLTextAreaElement, 'rows'>,
    ref: ForwardedRef<any>,
) => {
    const [isActive, setIsActive] = useState(false);

    const handleChange = (e: FormEvent<HTMLTextAreaElement>) => {
        if (e.currentTarget.value !== '') {
            setIsActive(true);
        } else {
            setIsActive(false);
        }

        if (typeof onChange === 'function') {
            onChange(e);
        }
    };

    return (
        <FloatingLabel isActive={isActive}>
            <Box as="label" htmlFor={name}>
                {label}
            </Box>
            <StyledTextArea
                ref={ref}
                name={name}
                hasError={Boolean(error)}
                value={value}
                onChange={handleChange}
                {...rest}
            />
            {Boolean(error) && (
                <Typography variant="caption" color="var(--red-warning)">
                    {error}
                </Typography>
            )}
        </FloatingLabel>
    );
};

export default forwardRef(TextArea);
