import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLayoutEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';

import Box from '@atoms/Box';

const query = graphql`
    query {
        fam1: file(relativePath: { eq: "carousel/fam_1.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        fam2: file(relativePath: { eq: "carousel/fam_2.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        fam3: file(relativePath: { eq: "carousel/fam_3.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        fam4: file(relativePath: { eq: "carousel/fam_4.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        fam5: file(relativePath: { eq: "carousel/fam_5.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        fam6: file(relativePath: { eq: "carousel/fam_6.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        fam7: file(relativePath: { eq: "carousel/fam_7.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        fam8: file(relativePath: { eq: "carousel/fam_8.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;

const PhotoCarousel = () => {
    const [perView, setPerView] = useState<number>(1.2);
    const { tablet, laptopS, desktop, desktopL } = useBreakpoint();
    const { fam1, fam2, fam3, fam4, fam5, fam6, fam7, fam8 } = useStaticQuery(query);
    const slides = [
        {
            key: 'fam1',
            img: fam1.childImageSharp.gatsbyImageData,
        },
        {
            key: 'fam2',
            img: fam2.childImageSharp.gatsbyImageData,
        },
        {
            key: 'fam3',
            img: fam3.childImageSharp.gatsbyImageData,
        },
        {
            key: 'fam4',
            img: fam4.childImageSharp.gatsbyImageData,
        },
        {
            key: 'fam5',
            img: fam5.childImageSharp.gatsbyImageData,
        },
        {
            key: 'fam6',
            img: fam6.childImageSharp.gatsbyImageData,
        },
        {
            key: 'fam7',
            img: fam7.childImageSharp.gatsbyImageData,
        },
        {
            key: 'fam8',
            img: fam8.childImageSharp.gatsbyImageData,
        },
    ];

    useLayoutEffect(() => {
        switch (true) {
            case desktop:
                setPerView(4);
                break;
            case laptopS:
                setPerView(3);
                break;
            case tablet:
                setPerView(1.5);
                break;
            default:
                setPerView(1.2);
                break;
        }
    }, [tablet, laptopS, desktop, desktopL]);

    return (
        <section id="carousel">
            <Box>
                <Marquee speed={30}>
                    {slides.map((slide) => (
                        <Box key={slide.key} width={`calc(100vw / ${perView})`}>
                            <GatsbyImage
                                alt="alt"
                                image={slide.img}
                                style={{
                                    maxHeight: '60rem',
                                    minHeight: '24rem',
                                }}
                            />
                        </Box>
                    ))}
                </Marquee>
            </Box>
        </section>
    );
};

export default PhotoCarousel;
