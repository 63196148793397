/* eslint-disable prefer-destructuring */
export const deviceList = ['mobile', 'tabletS', 'tablet', 'laptopS', 'laptop', 'desktop', 'desktopL'] as const;

const breakpoints = ['320px', '480px', '768px', '1024px', '1440px', '1920px', '2560px'] as BreakpointsProp;

export type Device = typeof deviceList[number];

type BreakpointsProp = Array<string> & { [key in Device]: string };

breakpoints.mobile = breakpoints[0];
breakpoints.tabletS = breakpoints[1];
breakpoints.tablet = breakpoints[2];
breakpoints.laptopS = breakpoints[3];
breakpoints.laptop = breakpoints[4];
breakpoints.desktop = breakpoints[5];
breakpoints.desktopL = breakpoints[6];

export default breakpoints;
