import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Box from '@atoms/Box';

import Contacts from '@molecules/Contacts';
import BackDrop from '@molecules/HamburgerMenu/BackDrop';
import BurgerButton from '@molecules/HamburgerMenu/BurgerButton';
import { Links } from '@molecules/HamburgerMenu/Links';
import SocialLinksBlock from '@molecules/SocialLinksBlock';

const Menu = styled.nav<{ isOpen: boolean }>`
    align-items: flex-end;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    max-width: 30.5rem;
    padding: 12rem 2rem 5rem;
    position: fixed;
    right: 0;
    top: 0;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndices.burgerMenu};
`;

const HamburgerMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleIsOpen = () => {
        if (isOpen) {
            setIsOpen(false);
            document.body.classList.remove('no-scroll');
        } else {
            setIsOpen(true);
            document.body.classList.add('no-scroll');
        }
    };

    useEffect(() => {
        const closeOnEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && isOpen) {
                toggleIsOpen();
            }
        };

        if (isOpen) {
            window.addEventListener('keydown', closeOnEscape);
        } else {
            window.removeEventListener('keydown', closeOnEscape);
        }
    }, [isOpen]);

    return (
        <Box height="4rem" width="4rem" position="relative">
            <Menu isOpen={isOpen}>
                <Links />
                <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                    <Contacts />
                    <SocialLinksBlock />
                </Box>
            </Menu>
            <BurgerButton isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
            <BackDrop isOpen={isOpen} onMouseDown={toggleIsOpen} />
        </Box>
    );
};

export default HamburgerMenu;
