import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Swiper } from 'swiper/types';

import { useSwiper } from '@hooks';

import colors from '@theme/configs/colors';

import Box from '@atoms/Box';
import Flex from '@atoms/Flex';
import GridChild from '@atoms/GridChild';
import GridParent from '@atoms/GridParent';
import ArrowLeft from '@atoms/Icons/ArrowLeft';
import ArrowRight from '@atoms/Icons/ArrowRight';
import Typography from '@atoms/Typography';

import Slider from '@molecules/Slider';

const query = graphql`
    query ourFamily {
        images: allFile(
            filter: { relativeDirectory: { regex: "/family/" } }
            sort: { order: ASC, fields: name }
        ) {
            edges {
                node {
                    name
                    id
                    relativePath
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

const StyledGrid = styled(GridParent)`
    overflow: hidden;
`;

const Slide = ({
    img,
    alt,
    position,
    name,
}: {
    img: IGatsbyImageData;
    alt: string;
    position: string;
    name: string;
}) => (
    <Box minWidth={{ _: '16.5rem', tablet: '28rem', desktop: '32rem' }}>
        <GatsbyImage image={img} alt={alt} />
        <Box backgroundColor={colors.white} p="1rem 2rem">
            <Typography variant="caption" color="brandAccent">
                {position}
            </Typography>
            <Typography variant="accent" color="brandBlack">
                {name}
            </Typography>
        </Box>
    </Box>
);

const OurFamily = () => {
    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const { t } = useTranslation();
    const { images } = useStaticQuery(query);
    const { sliderRef, nextSlide, prevSlide } = useSwiper();

    const handleSlideChange = (swiper: Swiper) => {
        if (swiper.isBeginning !== isStart) setIsStart(swiper.isBeginning);

        if (swiper.isEnd !== isEnd) setIsEnd(swiper.isEnd);
    };

    return (
        <section id="our-family">
            <StyledGrid mt="12rem">
                <GridChild gridColumn={{ _: '1/13', desktopL: '2/ 12' }}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="h2" color="brandBlack" mb="3rem">
                            {t('family.subHeading')}
                        </Typography>

                        <Flex gridGap="4.5rem">
                            <Box
                                aria-label="Previous"
                                aria-describedby="Show previous slide"
                                onClick={!isStart ? prevSlide : undefined}
                                opacity={!isStart ? 1 : 0.3}
                                display="flex"
                                height="100%"
                                color={colors.greyDark}
                                className={!isStart ? 'withAction' : undefined}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <ArrowLeft style={{ fill: 'transparent' }} />
                            </Box>

                            <Box
                                aria-label="Next"
                                aria-describedby="Show next slide"
                                onClick={!isEnd ? nextSlide : undefined}
                                opacity={!isEnd ? 1 : 0.3}
                                display="flex"
                                height="100%"
                                className={!isEnd ? 'withAction' : undefined}
                                color={colors.greyDark}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <ArrowRight style={{ fill: 'transparent' }} />
                            </Box>
                        </Flex>
                    </Box>

                    <Slider
                        sliderRef={sliderRef}
                        slidesPerView="auto"
                        showOverflow
                        spaceBetween={30}
                        shouldShrink
                        onSlideChange={handleSlideChange}
                    >
                        {images?.edges.map((e: any) => (
                            <Slide
                                key={e.node.name}
                                name="Kimiko Thompson"
                                position="Director"
                                alt={e.node.name}
                                img={e.node.childImageSharp.gatsbyImageData}
                            />
                        ))}
                        {images?.edges.map((e: any) => (
                            <Slide
                                key={e.node.name}
                                name="Kimiko Thompson"
                                position="Director"
                                alt={e.node.name}
                                img={e.node.childImageSharp.gatsbyImageData}
                            />
                        ))}
                        {images?.edges.map((e: any) => (
                            <Slide
                                key={e.node.name}
                                name="Kimiko Thompson"
                                position="Director"
                                alt={e.node.name}
                                img={e.node.childImageSharp.gatsbyImageData}
                            />
                        ))}
                    </Slider>
                </GridChild>
            </StyledGrid>
        </section>
    );
};

export default OurFamily;
