import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={18}
        height={32}
        viewBox="0 0 18 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M1.49998 0.999999L16.5 16L1.49998 31" stroke="currentColor" strokeWidth={2} />
    </svg>
);

const Memo = memo(SvgArrowRight);
export default Memo;
