import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgUpArrow = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={13}
        height={30}
        viewBox="0 0 13 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 6.72998L6.5 1.22998L1 6.72998"
            stroke="#4052F8"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M6.5 1.24997L6.5 28.73" stroke="#4052F8" strokeWidth={2} strokeLinecap="round" />
    </svg>
);

const Memo = memo(SvgUpArrow);
export default Memo;
