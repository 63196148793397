import { createGlobalStyle } from 'styled-components';

import mediaQueries from '@theme/configs/mediaQueries';

const GlobalStyle = createGlobalStyle`
  :root {
    --scrollbar-size: 1.2rem;
    --gutter: 1.5rem;
    
    ${mediaQueries.tablet} {
      --gutter: 4.5rem;
    }
  }
  
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding-top: auto;
  }

  html {
    ${({ theme }) => `
            --thumbBG: ${theme.colors.white};
            --scrollbarBG: ${theme.colors.greyDark};
        `};
  }
  *::-webkit-scrollbar {
    width: var(--scrollbar-size);
    height: var(--scrollbar-size);
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  *::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 9px;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul, li {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  svg {
    fill: currentColor;
  }

  textarea, select, input {
    outline: unset;
    border: 0;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 8px;

    &:disabled {
      opacity: 32%;
    }
  }

  a {
    text-decoration: none;

    svg {
      vertical-align: middle;
    }
  }
   
    html,
    body,
    #___gatsby,
    #gatsby-focus-wrapper { 
      height: 100%; 
    }
    
    // Trick to easier calculate rems. ( Now 16px in rems will be 1.6rem, 24px = 2.4rem, 30px = 3rem and so on)
    html {
      font-size: 62.5%;
    }
    
    body {
      font-size: 1.6rem;
      -webkit-font-smoothing: antialiased;
      background-color: ${({ theme }) => theme.colors.greyCyan};
      overflow-x: hidden;
    }

  body.no-scroll {
    overflow-y: hidden;
  }
 
    svg { 
      fill: currentColor;
    }
    
    form {
      width: 100%;
    }
    
    .withAction {
      cursor: pointer;
    }
    
    .swiper-slide {
      user-select: none;
    }
`;

export default GlobalStyle;
