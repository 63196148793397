import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgParagraphDots = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={22}
        height={22}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g opacity={0.5}>
            <circle cx={3} cy={3} r={3} fill="#4052F8" />
            <circle cx={19} cy={3} r={3} fill="#4052F8" />
            <circle cx={3} cy={19} r={3} fill="#4052F8" />
            <circle cx={19} cy={19} r={3} fill="#4052F8" />
        </g>
    </svg>
);

const Memo = memo(SvgParagraphDots);
export default Memo;
