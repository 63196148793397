import { ReactElement, useMemo, useState } from 'react';

import HeaderContext from './HeaderContext';

const HeaderProvider = ({ children }: { children: ReactElement }): ReactElement => {
    const [hasWhiteBg, setHasWhiteBgState] = useState<boolean>(false);

    const value = useMemo(() => {
        const toggleWhiteHeader = () => {
            if (hasWhiteBg) {
                setHasWhiteBgState(false);
                document.getElementsByClassName('header')[0]?.classList.remove('white-header');
            } else {
                setHasWhiteBgState(true);
                document.getElementsByClassName('header')[0]?.classList.add('white-header');
            }
        };

        return { hasWhiteBg, toggleWhiteHeader };
    }, [hasWhiteBg]);

    return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

export default HeaderProvider;
