import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={12}
        height={24}
        viewBox="0 0 12 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M2.96514 24H7.8088V11.9035H11.1666L11.5276 7.8405H7.8088V5.532C7.8088 4.57502 8.00187 4.19727 8.92527 4.19727H11.5528V0H8.19495C4.5853 0 2.96514 1.59495 2.96514 4.63378V7.87409H0.446777V11.9874H2.96514V24Z"
            fill="currentColor"
        />
    </svg>
);

const Memo = memo(SvgFacebook);
export default Memo;
