import styled from 'styled-components';
import { variant, ZIndexProps, zIndex } from 'styled-system';

export type OverlayTypes = 'default' | 'ctaGradient' | 'vertical' | 'verticalReverse' | 'dark';

const overlayVariants = variant({
    prop: 'overlay',
    variants: {
        default: {
            background:
                'linear-gradient(180deg, rgba(16, 21, 64, 0) 0%, rgba(16, 21, 64, 0.9) 100%)',
        },
        ctaGradient: {
            background: 'linear-gradient(89.95deg, #101540 0.05%, rgba(16, 21, 64, 0) 99.96%)',
        },
        vertical: {
            background:
                'transparent linear-gradient(180deg, #070F39CC 0%, #070F394D 100%) 0% 0% no-repeat padding-box',
        },
        verticalReverse: {
            background:
                'transparent linear-gradient(0deg, #070F39B3 0%, #070F3933 100%) 0% 0% no-repeat padding-box',
        },
        dark: {
            background:
                'transparent linear-gradient(180deg, #070F39E6 0%, #070F3980 100%) 0% 0% no-repeat padding-box;',
        },
    },
});

const OverlayBox = styled.div<{ overlay?: OverlayTypes } & ZIndexProps>`
    grid-area: 1 / 1 / auto / auto;
    height: 100%;
    place-items: center;
    width: 100%;

    ${overlayVariants};
    ${zIndex};
`;

export default OverlayBox;
