import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgSmartphone = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={22}
        height={24}
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.8499 7.30909L18.759 8.4C20.7227 10.3636 20.7227 13.4182 18.759 15.4909L19.8499 16.5818C22.5772 14.0727 22.5772 9.92727 19.8499 7.30909ZM17.559 9.6L16.4681 10.6909C17.0136 11.4545 17.0136 12.4364 16.4681 13.2L17.559 14.2909C18.8681 12.9818 18.8681 11.0182 17.559 9.6ZM13.1954 0H2.28631C1.08631 0 0.104492 0.981818 0.104492 2.18182V21.8182C0.104492 23.0182 1.08631 24 2.28631 24H13.1954C14.3954 24 15.3772 23.0182 15.3772 21.8182V2.18182C15.3772 0.981818 14.3954 0 13.1954 0ZM13.1954 20.7273H2.28631V3.27273H13.1954V20.7273Z"
            fill="currentColor"
        />
    </svg>
);

const Memo = memo(SvgSmartphone);
export default Memo;
