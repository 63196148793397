import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '@theme/configs/colors';
import mediaQueries from '@theme/configs/mediaQueries';

import Box from '@atoms/Box';
import Flex from '@atoms/Flex/Flex';
import GridChild from '@atoms/GridChild';
import GridParent from '@atoms/GridParent';
import { AppStoreIcon, GooglePlayIcon, UpArrowIcon } from '@atoms/Icons';
import Logo from '@atoms/Icons/logo/LogoColor';
import Typography from '@atoms/Typography';

import Contacts from '@molecules/Contacts';
import SocialLinksBlock from '@molecules/SocialLinksBlock';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12rem;
    position: relative;
    z-index: 1;

    ${mediaQueries.tablet} {
        margin-top: 18rem;
    }
`;

const StyledInfoBlock = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding: 2.25rem 1rem;

    ${mediaQueries.tablet} {
        padding: 2.8125rem 2.8125rem 1.25rem;
    }
`;

const StyledBackToTop = styled.div`
    align-items: center;
    color: ${({ theme }) => theme.colors.brandAccent};
    display: flex;
    grid-gap: 1.6rem;
    margin-left: 2rem;

    &:hover {
        cursor: pointer;
    }
`;

const StyledGridParent = styled(GridParent)`
    width: 100%;
`;

const Footer = () => {
    const { t } = useTranslation();
    const { laptopS } = useBreakpoint();

    const handleBackToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <footer>
            <Wrapper>
                <StyledInfoBlock>
                    <StyledGridParent as="div">
                        <GridChild gridColumn="1/13" gridRow="1">
                            <Flex justifyContent="space-between">
                                <Link to="/">
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        maxWidth="15rem"
                                    >
                                        <Logo />
                                    </Box>
                                </Link>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    flexWrap="wrap"
                                >
                                    {/* <LanguageDropdown language="en" originalPath="/" isDark /> */}
                                    <StyledBackToTop onClick={handleBackToTop}>
                                        <Typography variant="accent" color="brandAccent">
                                            {t('footer.backToTop')}
                                        </Typography>
                                        <UpArrowIcon />
                                    </StyledBackToTop>
                                </Box>
                            </Flex>
                        </GridChild>

                        {!laptopS && (
                            <GridChild gridColumn="1/13" my={{ _: 'unset', tablet: '4rem' }}>
                                <Box>
                                    <Typography variant="paragraph2" color="brandBlack" my="4rem">
                                        {t('footer.disclaimer')}
                                    </Typography>
                                </Box>
                            </GridChild>
                        )}

                        <GridChild gridColumn="1/13" my={{ _: 'unset', tablet: '4rem' }}>
                            <Flex
                                flexGrow={1}
                                justifyContent="space-between"
                                gridGap="3rem"
                                flexWrap="wrap"
                            >
                                <Box display="inline-flex" flexDirection="column" gridGap="1rem">
                                    <Typography variant="caption" color="greyDark" mb="1rem">
                                        {t('footer.download')}:
                                    </Typography>
                                    <Box
                                        display="inline-block"
                                        height="5rem"
                                        backgroundColor={colors.brandBlack}
                                        borderRadius={4}
                                        p="0.85rem 1.4rem"
                                    >
                                        <GooglePlayIcon />
                                    </Box>
                                    <Box
                                        display="inline-block"
                                        height="5rem"
                                        backgroundColor={colors.brandBlack}
                                        borderRadius={4}
                                        p="0.85rem 1.4rem"
                                    >
                                        <AppStoreIcon />
                                    </Box>
                                </Box>

                                {laptopS && (
                                    <Box maxWidth="46rem">
                                        <Typography
                                            variant="paragraph2"
                                            color="var(--grey-dark)"
                                            mt={{ _: 'unset', tablet: '3rem' }}
                                            pr="2rem"
                                        >
                                            {t('footer.disclaimer')}
                                        </Typography>
                                    </Box>
                                )}

                                <Box mt={{ _: 'unset', tablet: '3rem' }}>
                                    <Contacts />
                                </Box>

                                <SocialLinksBlock withBoundaries />
                            </Flex>
                        </GridChild>
                    </StyledGridParent>
                </StyledInfoBlock>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="5rem"
                    backgroundColor={colors.greyCyan}
                    py="1.2rem"
                >
                    <Typography variant="paragraph2" color="brandAccent" mr="2.5rem">
                        {t('footer.privacyPolicy')}
                    </Typography>
                    <Typography variant="paragraph2" color="brandAccent">
                        {t('footer.termOfUse')}
                    </Typography>
                </Box>
            </Wrapper>
        </footer>
    );
};
export default Footer;
