import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgSolutions = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={36}
        height={40}
        viewBox="0 0 36 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18 8C20.2091 8 22 6.20914 22 4C22 1.79086 20.2091 0 18 0C15.7909 0 14 1.79086 14 4C14 6.20914 15.7909 8 18 8Z"
            fill="white"
        />
        <path
            d="M33.6665 9.33398H2.33317C1.89114 9.33398 1.46722 9.50958 1.15466 9.82214C0.842099 10.1347 0.666504 10.5586 0.666504 11.0007C0.666504 11.4427 0.842099 11.8666 1.15466 12.1792C1.46722 12.4917 1.89114 12.6673 2.33317 12.6673L12.6665 13.334V38.0006C12.6665 38.5311 12.8772 39.0398 13.2523 39.4149C13.6274 39.7899 14.1361 40.0006 14.6665 40.0006C15.1969 40.0006 15.7056 39.7899 16.0807 39.4149C16.4558 39.0398 16.6665 38.5311 16.6665 38.0006L17.3332 24.0007H18.6665L19.3332 38.0006C19.3332 38.5311 19.5439 39.0398 19.919 39.4149C20.294 39.7899 20.8027 40.0006 21.3332 40.0006C21.8636 40.0006 22.3723 39.7899 22.7474 39.4149C23.1225 39.0398 23.3332 38.5311 23.3332 38.0006V13.334L33.6665 12.6673C34.1085 12.6673 34.5325 12.4917 34.845 12.1792C35.1576 11.8666 35.3332 11.4427 35.3332 11.0007C35.3332 10.5586 35.1576 10.1347 34.845 9.82214C34.5325 9.50958 34.1085 9.33398 33.6665 9.33398Z"
            fill="white"
        />
    </svg>
);

const Memo = memo(SvgSolutions);
export default Memo;
