import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import mediaQueries from '@theme/configs/mediaQueries';

import Box from '@atoms/Box';
import { CheckIcon } from '@atoms/Icons';
import Typography from '@atoms/Typography';

import CallUsBtn from '@molecules/Form/CallUsBtn';

const IconWrapper = styled.div`
    align-items: center;
    aspect-ratio: 1;
    border: 1px solid ${({ theme }) => theme.colors.brandAccent};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.brandAccent};
    display: flex;
    flex: 1;
    max-width: 8rem;

    padding: 2rem;

    ${mediaQueries.tabletS} {
        justify-content: center;
        max-width: 15rem;
        min-width: 11rem;
    }

    svg {
        fill: transparent;
        height: 100%;
        width: 100%;

        path {
            stroke-width: 1;
        }
    }
`;

const SuccessBlock = () => {
    const { t } = useTranslation();

    return (
        <Box
            key="form"
            as={motion(Box)}
            intitial={{ opacity: 0, maxHeight: '500px' }}
            animate={{ opacity: 1, maxHeight: '300px' }}
            exit={{ opacity: 0, maxHeight: '500px' }}
            display="flex"
            flexWrap="wrap"
            width="100%"
            gridColumnGap="2rem"
            height="100%"
            justifyContent={{ _: 'flex-start', tabletS: 'center' }}
            alignItems="center"
        >
            <IconWrapper>
                <CheckIcon />
            </IconWrapper>
            <Box>
                <Typography variant="h2" color="white" mb="1rem">
                    {t('form.success.heading')}
                </Typography>
                <Typography variant="paragraph" color="white" mb="1rem">
                    {t('form.success.caption')}
                </Typography>
                <CallUsBtn />
            </Box>
        </Box>
    );
};

export default SuccessBlock;
