import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgLogoGreyFull = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={589}
        height={169}
        viewBox="0 0 589 169"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_3339_174)">
            <g opacity={0.8}>
                <path
                    d="M73.649 83.6869C73.3013 83.0795 72.7971 82.5753 72.1885 82.2261C71.5798 81.8769 70.8886 81.6953 70.186 81.7C69.4828 81.6953 68.7911 81.8774 68.1823 82.2274C67.5735 82.5775 67.0697 83.0828 66.7229 83.6913L50.346 111.982C50.0728 112.45 49.6818 112.838 49.2117 113.109C48.7415 113.381 48.2084 113.525 47.665 113.529H43.5495C43.0056 113.525 42.4723 113.379 42.0021 113.107C41.5319 112.835 41.1412 112.446 40.8685 111.978L38.8219 108.453C38.5474 107.98 38.4028 107.444 38.4028 106.898C38.4028 106.351 38.5474 105.815 38.8219 105.342L55.3284 77.1175C55.6754 76.5126 55.8576 75.828 55.8571 75.1315C55.8565 74.4351 55.6731 73.7508 55.3251 73.1465C54.9771 72.5421 54.4765 72.0386 53.8728 71.6858C53.2691 71.333 52.5833 71.143 51.8832 71.1348H47.9465C47.1667 71.1394 46.4017 71.347 45.7275 71.7369C45.0534 72.1268 44.4935 72.6854 44.1036 73.3572L25.8723 104.68C25.4843 105.353 25.2801 106.115 25.2801 106.891C25.2801 107.667 25.4843 108.429 25.8723 109.102L33.8083 122.823C34.1982 123.495 34.758 124.054 35.4322 124.444C36.1064 124.833 36.8714 125.041 37.6512 125.046L53.5455 125.068C54.3253 125.063 55.0903 124.856 55.7644 124.466C56.4386 124.076 56.9984 123.517 57.3883 122.846L75.6241 91.5142C76.0163 90.8385 76.2227 90.072 76.2227 89.2918C76.2227 88.5115 76.0163 87.7451 75.6241 87.0694L73.649 83.6869Z"
                    fill="white"
                />
                <path
                    d="M94.2441 80.8025L130.617 80.8559C131.398 80.852 132.164 80.6447 132.839 80.2548C133.514 79.8649 134.074 79.3059 134.465 78.6335L142.432 64.9302C142.822 64.2585 143.027 63.4966 143.027 62.7211C143.027 61.9456 142.822 61.1837 142.432 60.512L134.5 46.8176C134.11 46.1458 133.551 45.5873 132.876 45.1974C132.202 44.8075 131.437 44.5999 130.658 44.5952L94.2843 44.5508C93.5038 44.5547 92.7378 44.7619 92.0628 45.1518C91.3878 45.5418 90.8273 46.1008 90.437 46.7732L88.4709 50.1646C88.1264 50.7704 87.9468 51.4552 87.9499 52.1512C87.9529 52.8472 88.1385 53.5304 88.4882 54.1332C88.8379 54.736 89.3397 55.2377 89.944 55.5886C90.5483 55.9394 91.2342 56.1274 91.9339 56.1339L124.741 56.1028C125.285 56.1072 125.819 56.2526 126.289 56.5246C126.759 56.7966 127.15 57.1859 127.422 57.654L129.464 61.1877C129.739 61.6606 129.884 62.1972 129.884 62.7433C129.884 63.2895 129.739 63.826 129.464 64.299L127.418 67.8148C127.144 68.2813 126.753 68.6688 126.283 68.9393C125.813 69.2097 125.28 69.3537 124.737 69.3572L91.925 69.2461C91.2237 69.2451 90.5346 69.4278 89.9268 69.7758C89.3191 70.1238 88.8141 70.6247 88.4627 71.2284C88.1112 71.832 87.9258 72.517 87.9248 73.2145C87.9239 73.9121 88.1076 74.5976 88.4575 75.2021L90.4146 78.6024C90.8062 79.2681 91.3651 79.821 92.0366 80.2067C92.708 80.5925 93.4688 80.7978 94.2441 80.8025Z"
                    fill="white"
                />
                <path
                    d="M40.9712 13.0593C41.2445 12.5921 41.6355 12.2036 42.1056 11.9324C42.5758 11.6612 43.1088 11.5165 43.6523 11.5126H47.7499C48.2942 11.5174 48.8279 11.6634 49.2981 11.9363C49.7683 12.2091 50.1588 12.5993 50.4309 13.0682L66.7229 41.4038C67.068 42.0128 67.5698 42.5195 68.1768 42.8718C68.7839 43.2241 69.4742 43.4093 70.177 43.4084C70.8773 43.4124 71.566 43.2314 72.1729 42.8839C72.7798 42.5364 73.283 42.0348 73.6311 41.4305L75.6107 38.0435C76.0029 37.3678 76.2093 36.6014 76.2093 35.8211C76.2093 35.0409 76.0029 34.2744 75.6107 33.5987L57.4688 2.21849C57.0789 1.54671 56.519 0.988129 55.8449 0.598251C55.1707 0.208372 54.4057 0.000775765 53.6259 -0.00390625L37.7897 0.000538545C37.0122 0.00753745 36.2499 0.216231 35.5783 0.605994C34.9067 0.995757 34.349 1.5531 33.9602 2.22294L25.9617 15.8996C25.5737 16.5726 25.3695 17.335 25.3695 18.1109C25.3695 18.8867 25.5737 19.6491 25.9617 20.3221L44.1036 51.7024C44.4943 52.3755 45.0555 52.9349 45.7314 53.3248C46.4073 53.7148 47.1741 53.9217 47.9554 53.9248H51.8921C52.5921 53.918 53.2781 53.7295 53.8824 53.3781C54.4867 53.0267 54.9883 52.5244 55.3377 51.921C55.687 51.3177 55.872 50.634 55.8743 49.9377C55.8765 49.2415 55.6961 48.5566 55.3507 47.951L38.9202 19.6776C38.6457 19.2047 38.5012 18.6681 38.5012 18.122C38.5012 17.5758 38.6457 17.0393 38.9202 16.5663L40.9712 13.0593Z"
                    fill="white"
                />
            </g>
            <g opacity={0.5}>
                <path
                    d="M90.008 17.5932L92.6221 13.0862C92.8948 12.618 93.2855 12.2287 93.7557 11.9567C94.2259 11.6847 94.7592 11.5393 95.3031 11.5349H99.3873C99.9312 11.5393 100.465 11.6847 100.935 11.9567C101.405 12.2287 101.796 12.618 102.068 13.0862L104.115 16.6198C104.389 17.0927 104.534 17.6293 104.534 18.1754C104.534 18.7216 104.389 19.2581 104.115 19.7311L101.496 24.2648C101.153 24.8717 100.976 25.5571 100.981 26.2533C100.986 26.9496 101.173 27.6324 101.525 28.2344C101.876 28.8364 102.38 29.3368 102.985 29.6859C103.591 30.0351 104.277 30.221 104.977 30.2253H108.829C109.607 30.2191 110.371 30.0107 111.043 29.6209C111.715 29.2311 112.274 28.6734 112.663 28.0029L117.073 20.4023C117.461 19.7298 117.664 18.9682 117.664 18.1932C117.664 17.4182 117.461 16.6567 117.073 15.9842L109.178 2.27197C108.788 1.60019 108.228 1.0416 107.554 0.651725C106.88 0.261846 106.115 0.0542497 105.335 0.0495677L89.4271 0.0273438C88.6466 0.0312514 87.8806 0.238481 87.2056 0.628398C86.5306 1.01832 85.9701 1.57732 85.5798 2.24974L81.1605 9.8459C80.7683 10.5216 80.5618 11.2881 80.5618 12.0683C80.5618 12.8485 80.7683 13.615 81.1605 14.2907L83.0953 17.6332C83.4416 18.2425 83.9451 18.7487 84.5539 19.0996C85.1628 19.4505 85.8548 19.6332 86.5584 19.6289C87.2637 19.6268 87.9557 19.437 88.5623 19.079C89.1689 18.721 89.6681 18.208 90.008 17.5932Z"
                    fill="white"
                />
                <path
                    d="M27.2173 71.358C26.8718 70.7445 26.3678 70.2339 25.7574 69.8791C25.147 69.5244 24.4523 69.3383 23.7454 69.3401H18.4502C17.9068 69.3362 17.3737 69.1915 16.9036 68.9202C16.4334 68.649 16.0425 68.2606 15.7692 67.7933L13.7003 64.2374C13.4257 63.7645 13.2812 63.2279 13.2812 62.6818C13.2812 62.1356 13.4257 61.5991 13.7003 61.1261L15.7424 57.5703C16.0162 57.0966 16.4107 56.703 16.8861 56.4291C17.3614 56.1553 17.9009 56.0108 18.4502 56.0101H23.7051C24.4108 56.0087 25.1037 55.8226 25.7142 55.4705C26.3247 55.1184 26.8312 54.6127 27.1831 54.0042C27.5349 53.3957 27.7195 52.7059 27.7185 52.0039C27.7175 51.302 27.5308 50.6127 27.1771 50.0052L25.1887 46.6716C24.7965 45.9959 24.2324 45.4348 23.5531 45.0447C22.8739 44.6546 22.1033 44.4492 21.319 44.4492L12.4536 44.4759C11.6715 44.4782 10.9037 44.6847 10.227 45.0747C9.5503 45.4647 8.98834 46.0246 8.5973 46.6983L0.64793 60.5349C0.255741 61.2106 0.0492706 61.9771 0.0492706 62.7573C0.0492706 63.5376 0.255741 64.304 0.64793 64.9797L8.69114 78.7186C9.08256 79.393 9.64522 79.9532 10.3228 80.3432C11.0004 80.7333 11.769 80.9394 12.5519 80.941L21.4486 80.8965C22.2299 80.8934 22.9967 80.6865 23.6726 80.2966C24.3485 79.9066 24.9097 79.3472 25.3004 78.6741L27.2307 75.3494C27.5835 74.7424 27.7682 74.0531 27.7658 73.3519C27.7635 72.6507 27.5742 71.9627 27.2173 71.358Z"
                    fill="white"
                />
            </g>
            <path
                d="M429.029 116.516V111.182C429.029 109.431 428.683 107.697 428.009 106.079C427.335 104.461 426.348 102.991 425.103 101.753C423.858 100.515 422.381 99.5328 420.754 98.8627C419.128 98.1926 417.385 97.8477 415.624 97.8477H370.94C367.384 97.8477 363.975 99.2525 361.461 101.753C358.947 104.254 357.534 107.646 357.534 111.182V155.63C357.534 159.167 358.947 162.558 361.461 165.059C363.975 167.56 367.384 168.964 370.94 168.964H415.624C417.385 168.964 419.128 168.619 420.754 167.949C422.381 167.279 423.858 166.297 425.103 165.059C426.348 163.821 427.335 162.351 428.009 160.733C428.683 159.115 429.029 157.381 429.029 155.63V150.261C429.029 149.553 428.747 148.875 428.244 148.375C427.741 147.875 427.059 147.594 426.348 147.594H416.518C415.807 147.594 415.125 147.875 414.622 148.375C414.119 148.875 413.837 149.553 413.837 150.261V152.074C413.837 153.253 413.366 154.384 412.528 155.217C411.69 156.051 410.553 156.519 409.368 156.519H377.195C376.01 156.519 374.874 156.051 374.036 155.217C373.198 154.384 372.727 153.253 372.727 152.074V114.738C372.727 113.559 373.198 112.428 374.036 111.595C374.874 110.761 376.01 110.293 377.195 110.293H409.368C410.553 110.293 411.69 110.761 412.528 111.595C413.366 112.428 413.837 113.559 413.837 114.738V116.516C413.837 117.223 414.119 117.901 414.622 118.402C415.125 118.902 415.807 119.183 416.518 119.183H426.348C427.059 119.183 427.741 118.902 428.244 118.402C428.747 117.901 429.029 117.223 429.029 116.516Z"
                fill="white"
            />
            <path
                d="M158.241 97.8477H92.1082C91.3971 97.8477 90.7152 98.1286 90.2124 98.6288C89.7096 99.1289 89.4271 99.8072 89.4271 100.515V107.626C89.4271 108.334 89.7096 109.012 90.2124 109.512C90.7152 110.012 91.3971 110.293 92.1082 110.293H117.52V166.333C117.52 167.04 117.803 167.719 118.306 168.219C118.808 168.719 119.49 169 120.201 169H130.148C130.859 169 131.541 168.719 132.044 168.219C132.547 167.719 132.829 167.04 132.829 166.333V110.293H158.241C158.952 110.293 159.634 110.012 160.137 109.512C160.64 109.012 160.922 108.334 160.922 107.626V100.515C160.922 99.8072 160.64 99.1289 160.137 98.6288C159.634 98.1286 158.952 97.8477 158.241 97.8477Z"
                fill="white"
            />
            <path
                d="M336.979 97.8481L270.846 97.8125C270.135 97.8125 269.453 98.0935 268.95 98.5936C268.448 99.0937 268.165 99.7721 268.165 100.479V166.298C268.165 167.005 268.448 167.684 268.95 168.184C269.453 168.684 270.135 168.965 270.846 168.965H336.979C337.69 168.965 338.372 168.684 338.875 168.184C339.378 167.684 339.66 167.005 339.66 166.298V159.186C339.66 158.479 339.378 157.801 338.875 157.3C338.372 156.8 337.69 156.519 336.979 156.519H283.358V139.594H328.042C328.753 139.594 329.435 139.313 329.938 138.812C330.441 138.312 330.723 137.634 330.723 136.927V129.815C330.723 129.108 330.441 128.429 329.938 127.929C329.435 127.429 328.753 127.148 328.042 127.148H283.358V110.293H336.979C337.69 110.293 338.372 110.013 338.875 109.512C339.378 109.012 339.66 108.334 339.66 107.627V100.515C339.66 99.8076 339.378 99.1293 338.875 98.6292C338.372 98.129 337.69 97.8481 336.979 97.8481Z"
                fill="white"
            />
            <path
                d="M247.61 97.8125H237.78C237.069 97.8125 236.387 98.0935 235.884 98.5936C235.381 99.0937 235.099 99.7721 235.099 100.479V127.148H193.989V100.479C193.989 99.7721 193.706 99.0937 193.204 98.5936C192.701 98.0935 192.019 97.8125 191.308 97.8125H181.477C180.766 97.8125 180.084 98.0935 179.581 98.5936C179.079 99.0937 178.796 99.7721 178.796 100.479V166.262C178.796 166.97 179.079 167.648 179.581 168.148C180.084 168.648 180.766 168.929 181.477 168.929H191.308C192.019 168.929 192.701 168.648 193.204 168.148C193.706 167.648 193.989 166.97 193.989 166.262V139.594H235.099V166.262C235.099 166.97 235.381 167.648 235.884 168.148C236.387 168.648 237.069 168.929 237.78 168.929H247.61C248.321 168.929 249.003 168.648 249.506 168.148C250.009 167.648 250.291 166.97 250.291 166.262V100.479C250.291 99.7721 250.009 99.0937 249.506 98.5936C249.003 98.0935 248.321 97.8125 247.61 97.8125Z"
                fill="white"
            />
            <g opacity={0.5}>
                <path
                    d="M463.311 114.151V100.71H458.191V97.834H471.507V100.71H466.381V114.151H463.311Z"
                    fill="white"
                />
                <path
                    d="M473.312 114.151V97.834H476.382V104.541H483.647V97.834H486.717V114.151H483.647V107.417H476.382V114.151H473.312Z"
                    fill="white"
                />
                <path
                    d="M489.881 114.151V97.834H500.489V100.71H492.946V104.199H499.126V107.079H492.937V111.271H500.48V114.151H489.881Z"
                    fill="white"
                />
                <path
                    d="M507.71 114.151V97.834H510.78V104.541H518.045V97.834H521.115V114.151H518.045V107.417H510.78V114.151H507.71Z"
                    fill="white"
                />
                <path
                    d="M530.888 114.489C529.689 114.514 528.502 114.239 527.438 113.689C526.461 113.177 525.653 112.396 525.11 111.44C524.535 110.396 524.247 109.22 524.275 108.03V97.8562H527.402V108.008C527.396 108.508 527.487 109.005 527.671 109.47C527.845 109.893 528.1 110.278 528.421 110.604C528.741 110.919 529.121 111.167 529.538 111.333C530.42 111.679 531.401 111.679 532.282 111.333C532.699 111.165 533.078 110.914 533.395 110.595C533.714 110.27 533.964 109.884 534.132 109.462C534.314 109 534.403 108.508 534.396 108.013V97.834H537.523V108.03C537.553 109.22 537.266 110.396 536.692 111.44C536.149 112.396 535.341 113.177 534.364 113.689C533.292 114.243 532.096 114.518 530.888 114.489Z"
                    fill="white"
                />
                <path
                    d="M540.683 114.151V97.834H543.435L548.797 108.666L554.159 97.834H556.912V114.151H554.048V104.403L549.329 114.151H548.248L543.529 104.403V114.151H540.683Z"
                    fill="white"
                />
                <path
                    d="M558.945 114.151L563.919 97.834H568.503L573.468 114.151H570.308L565.885 99.7586H566.47L562.113 114.151H558.945ZM561.926 110.751V107.87H570.501V110.751H561.926Z"
                    fill="white"
                />
                <path
                    d="M575.487 114.151V97.834H578.615L585.68 108.71V97.834H588.808V114.151H585.68L578.615 103.27V114.151H575.487Z"
                    fill="white"
                />
                <path
                    d="M459.545 141.344V125.031H470.153V127.907H462.614V131.396H468.799V134.276H462.614V138.468H470.153V141.344H459.545Z"
                    fill="white"
                />
                <path
                    d="M476.359 141.344L471.395 125.031H474.55L478.611 138.446L482.74 125.031H485.904L480.939 141.344H476.359Z"
                    fill="white"
                />
                <path
                    d="M494.121 141.682C492.649 141.721 491.194 141.352 489.921 140.615C488.742 139.908 487.796 138.873 487.2 137.637C486.572 136.239 486.248 134.724 486.248 133.193C486.248 131.661 486.572 130.146 487.2 128.748C487.797 127.513 488.743 126.479 489.921 125.77C491.21 125.07 492.655 124.703 494.124 124.703C495.592 124.703 497.037 125.07 498.326 125.77C499.504 126.48 500.449 127.514 501.047 128.748C501.672 130.147 501.995 131.661 501.995 133.193C501.995 134.724 501.672 136.238 501.047 137.637C500.45 138.872 499.504 139.906 498.326 140.615C497.051 141.352 495.595 141.721 494.121 141.682ZM494.121 138.78C495.028 138.831 495.929 138.598 496.695 138.113C497.396 137.629 497.934 136.946 498.237 136.153C498.596 135.205 498.771 134.197 498.751 133.184C498.771 132.176 498.595 131.174 498.232 130.232C497.925 129.448 497.388 128.773 496.691 128.294C495.922 127.811 495.026 127.563 494.117 127.583C493.21 127.535 492.311 127.768 491.543 128.25C490.842 128.733 490.306 129.416 490.006 130.21C489.645 131.158 489.47 132.166 489.492 133.179C489.468 134.185 489.643 135.187 490.006 136.126C490.31 136.911 490.846 137.587 491.543 138.064C492.313 138.549 493.21 138.798 494.121 138.78Z"
                    fill="white"
                />
                <path
                    d="M504.488 141.344V125.031H507.558V138.468H514.556V141.344H504.488Z"
                    fill="white"
                />
                <path
                    d="M523.081 141.682C521.881 141.708 520.693 141.433 519.627 140.882C518.653 140.368 517.846 139.588 517.304 138.633C516.726 137.59 516.438 136.414 516.468 135.224V125.05H519.596V135.224C519.59 135.724 519.681 136.22 519.864 136.686C520.039 137.108 520.294 137.493 520.615 137.819C520.934 138.135 521.314 138.383 521.732 138.548C522.159 138.716 522.614 138.802 523.072 138.802C523.545 138.807 524.014 138.719 524.453 138.544C524.87 138.374 525.248 138.123 525.566 137.806C525.888 137.483 526.139 137.097 526.303 136.673C526.487 136.212 526.578 135.719 526.571 135.224V125.027H529.699V135.224C529.727 136.413 529.439 137.589 528.864 138.633C528.319 139.587 527.513 140.367 526.54 140.882C525.473 141.434 524.284 141.709 523.081 141.682Z"
                    fill="white"
                />
                <path
                    d="M536.621 141.344V127.903H531.5V125.027H544.816V127.903H539.691V141.344H536.621Z"
                    fill="white"
                />
                <path d="M546.849 141.344V125.031H549.919V141.344H546.849Z" fill="white" />
                <path
                    d="M560.277 141.682C558.803 141.721 557.347 141.352 556.072 140.615C554.895 139.906 553.951 138.871 553.355 137.637C552.727 136.239 552.403 134.724 552.403 133.193C552.403 131.661 552.727 130.146 553.355 128.748C553.952 127.515 554.896 126.48 556.072 125.77C557.362 125.07 558.808 124.703 560.277 124.703C561.746 124.703 563.192 125.07 564.482 125.77C565.658 126.48 566.601 127.515 567.198 128.748C567.826 130.146 568.151 131.661 568.151 133.193C568.151 134.724 567.826 136.239 567.198 137.637C566.603 138.871 565.659 139.906 564.482 140.615C563.207 141.352 561.751 141.721 560.277 141.682ZM560.277 138.78C561.184 138.831 562.084 138.598 562.851 138.113C563.552 137.629 564.089 136.946 564.392 136.153C564.75 135.204 564.923 134.197 564.902 133.184C564.924 132.178 564.752 131.177 564.392 130.237C564.085 129.452 563.548 128.777 562.851 128.299C562.082 127.815 561.186 127.568 560.277 127.588C559.37 127.538 558.47 127.772 557.703 128.254C557.002 128.738 556.465 129.421 556.161 130.215C555.803 131.163 555.628 132.171 555.647 133.184C555.626 134.19 555.801 135.19 556.161 136.131C556.469 136.915 557.006 137.59 557.703 138.068C558.472 138.552 559.367 138.8 560.277 138.78Z"
                    fill="white"
                />
                <path
                    d="M570.411 141.344V125.032H573.539L580.604 135.908V125.027H583.732V141.344H580.604L573.539 130.468V141.344H570.411Z"
                    fill="white"
                />
                <path
                    d="M466.506 168.876C465.032 168.916 463.576 168.546 462.301 167.809C461.123 167.1 460.178 166.066 459.58 164.831C458.955 163.432 458.632 161.918 458.632 160.386C458.632 158.855 458.955 157.341 459.58 155.942C460.176 154.706 461.122 153.671 462.301 152.964C463.576 152.227 465.032 151.857 466.506 151.897C468.184 151.841 469.835 152.331 471.207 153.293C472.482 154.232 473.421 155.553 473.888 157.062L470.796 157.924C470.547 157.014 470.013 156.206 469.272 155.617C468.47 155.044 467.497 154.756 466.511 154.799C465.601 154.766 464.703 155.007 463.932 155.488C463.228 155.967 462.686 156.648 462.377 157.44C461.686 159.35 461.686 161.441 462.377 163.351C462.686 164.143 463.228 164.823 463.932 165.302C464.703 165.784 465.601 166.024 466.511 165.991C467.499 166.034 468.472 165.742 469.272 165.165C470.012 164.578 470.545 163.774 470.796 162.867L473.888 163.724C473.423 165.235 472.483 166.557 471.207 167.498C469.832 168.453 468.182 168.937 466.506 168.876Z"
                    fill="white"
                />
                <path
                    d="M483.357 168.875C481.883 168.915 480.427 168.546 479.152 167.809C477.975 167.099 477.029 166.064 476.431 164.831C475.806 163.431 475.483 161.917 475.483 160.386C475.483 158.855 475.806 157.34 476.431 155.941C477.028 154.707 477.974 153.672 479.152 152.963C480.442 152.263 481.888 151.896 483.357 151.896C484.826 151.896 486.272 152.263 487.562 152.963C488.739 153.672 489.683 154.707 490.278 155.941C490.906 157.34 491.231 158.854 491.231 160.386C491.231 161.918 490.906 163.432 490.278 164.831C489.681 166.064 488.738 167.098 487.562 167.809C486.286 168.545 484.831 168.914 483.357 168.875ZM483.357 165.973C484.263 166.021 485.163 165.788 485.931 165.306C486.632 164.824 487.168 164.14 487.468 163.346C487.829 162.398 488.004 161.39 487.982 160.377C488.005 159.371 487.831 158.37 487.468 157.43C487.163 156.645 486.628 155.969 485.931 155.492C485.161 155.009 484.266 154.762 483.357 154.781C482.45 154.73 481.55 154.963 480.783 155.448C480.082 155.931 479.545 156.615 479.241 157.408C478.88 158.356 478.705 159.364 478.728 160.377C478.704 161.383 478.878 162.384 479.241 163.324C479.549 164.109 480.086 164.784 480.783 165.262C481.552 165.746 482.447 165.993 483.357 165.973Z"
                    fill="white"
                />
                <path
                    d="M493.491 168.533V152.221H496.244L501.606 163.053L506.968 152.221H509.725V168.533H506.856V158.79L502.138 168.533H501.056L496.338 158.79V168.533H493.491Z"
                    fill="white"
                />
                <path
                    d="M512.902 168.533V152.221H519.761H520.4C520.64 152.23 520.879 152.255 521.115 152.296C521.982 152.404 522.805 152.737 523.501 153.261C524.118 153.743 524.592 154.382 524.873 155.11C525.466 156.715 525.466 158.478 524.873 160.084C524.589 160.812 524.114 161.452 523.497 161.937C522.803 162.46 521.983 162.793 521.12 162.902C520.91 162.933 520.673 162.955 520.4 162.968C520.128 162.982 519.918 162.99 519.766 162.99H515.977V168.52L512.902 168.533ZM515.972 160.128H519.627C519.784 160.128 519.958 160.128 520.146 160.106C520.321 160.093 520.495 160.061 520.664 160.012C521.036 159.926 521.371 159.727 521.625 159.443C521.843 159.184 522.003 158.881 522.094 158.555C522.267 157.944 522.267 157.298 522.094 156.688C522.003 156.362 521.843 156.059 521.625 155.799C521.371 155.515 521.036 155.316 520.664 155.23C520.49 155.175 520.31 155.139 520.128 155.123C519.94 155.123 519.766 155.101 519.609 155.101H515.954L515.972 160.128Z"
                    fill="white"
                />
                <path
                    d="M525.316 168.533L530.28 152.221H534.874L539.843 168.533H536.661L532.237 154.145H532.827L528.47 168.533H525.316ZM528.296 165.137V162.257H536.871V165.137H528.296Z"
                    fill="white"
                />
                <path
                    d="M541.858 168.533V152.221H544.986L552.05 163.097V152.221H555.178V168.533H552.05L544.972 157.657V168.533H541.858Z"
                    fill="white"
                />
                <path
                    d="M562.283 168.533V161.893L556.733 152.221H560.308L563.829 158.359L567.35 152.221H570.925L565.375 161.893V168.533H562.283Z"
                    fill="white"
                />
                <path d="M449.008 97.834H446.966V168.875H449.008V97.834Z" fill="white" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_3339_174">
                <rect width={589} height={169} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const Memo = memo(SvgLogoGreyFull);
export default Memo;
