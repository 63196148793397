import styled from 'styled-components';

import mediaQueries from '@theme/configs/mediaQueries';

const Container = styled.div`
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    ${mediaQueries.tablet} {
        margin-left: 4.5rem;
        margin-right: 4.5rem;
    }
`;

export default Container;
