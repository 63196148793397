import { hexToRGB } from '@utils/helpers';
import styled from 'styled-components';

const BackDrop = styled.div<{ isOpen: boolean }>`
    background-color: ${({ theme }) => `rgba(${hexToRGB(theme.colors.brandBlack)}, 0.8)`};
    height: 100vh;
    left: ${({ isOpen }) => (isOpen ? 0 : '-100%')};
    position: fixed;
    top: 0;
    transition: left 0.3s ease-in-out;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndices.backdrop};
`;

export default BackDrop;
