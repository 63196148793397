import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import colors from '@theme/configs/colors';
import mediaQueries from '@theme/configs/mediaQueries';

import Box from '@atoms/Box';
import GridChild from '@atoms/GridChild';
import GridParent from '@atoms/GridParent';
import Typography from '@atoms/Typography';

import ParagraphDots from '@molecules/ParagraphDots';

const query = graphql`
    query {
        bg: file(relativePath: { eq: "history_bg.png" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        eye: file(relativePath: { eq: "eye.png" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;

const ImageWrapper = styled.div`
    box-shadow: ${({ theme }) => theme.shadows.dropShadow};
    margin-bottom: 7rem;
    margin-left: calc(0rem - var(--gutter));
    margin-top: 7rem;
    width: calc(100% + var(--gutter) + var(--gutter));

    ${mediaQueries.laptopS} {
        width: calc(100% + var(--gutter));
    }
`;

const DesktopBackground = ({ image }: { image: IGatsbyImageData }) => (
    <GatsbyImage
        alt="test"
        image={image}
        objectPosition="30% top"
        style={{ maxHeight: '720px', width: '100%' }}
    />
);

const TabletBackground = ({ image }: { image: IGatsbyImageData }) => (
    <GatsbyImage
        alt="test"
        image={image}
        objectPosition="left top"
        style={{ maxHeight: '600px', width: '100%', aspectRatio: '768/600' }}
    />
);

const MobileBackground = ({ image }: { image: IGatsbyImageData }) => (
    <GatsbyImage
        alt="test"
        image={image}
        objectPosition="30% top"
        style={{ maxHeight: '720px', width: '100%', aspectRatio: '1/1' }}
    />
);

const Card = styled(GridChild)`
    margin-top: -60%;

    ${mediaQueries.tablet} {
        margin-top: -40%;
    }

    ${mediaQueries.laptopS} {
        margin-top: unset;
    }
`;

const History = () => {
    const { t } = useTranslation();
    const { bg, eye } = useStaticQuery(query);
    const { desktop, tablet } = useBreakpoint();

    return (
        <section id="history">
            <GridParent mt={{ _: '10rem', tablet: '18rem' }}>
                <GridChild gridRow={1} gridColumn="2 / 13">
                    <Box>
                        <Typography variant="h1" as="h2" color="brandBlack">
                            {t('history.heading')}
                        </Typography>
                    </Box>
                </GridChild>
                <GridChild
                    gridRow="2"
                    gridColumn={{ _: '1/13', desktop: '1/13', desktopL: '1/12' }}
                >
                    <ImageWrapper>
                        {desktop && (
                            <DesktopBackground image={bg.childImageSharp.gatsbyImageData} />
                        )}
                        {!desktop && tablet && (
                            <TabletBackground image={bg.childImageSharp.gatsbyImageData} />
                        )}
                        {!desktop && !tablet && (
                            <MobileBackground image={bg.childImageSharp.gatsbyImageData} />
                        )}
                    </ImageWrapper>
                </GridChild>
                <Card
                    gridRow={{ _: 3, laptopS: 2 }}
                    gridColumn={{
                        _: '1/13',
                        laptopS: '6/ 12',
                        laptop: '7/12',
                    }}
                >
                    <Box
                        backgroundColor={colors.black}
                        height="100%"
                        width="100%"
                        maxWidth={{ _: '68rem', tablet: '45.4rem', laptopS: '80rem' }}
                        p="0 5rem 7rem"
                    >
                        <GatsbyImage
                            alt="eye"
                            image={eye.childImageSharp.gatsbyImageData}
                            style={{ marginBottom: '3rem' }}
                        />
                        <ParagraphDots>
                            <Typography
                                variant={{ _: 'h3', laptop: 'h2' }}
                                as="p"
                                color="grey"
                                mb="3rem"
                            >
                                {t('history.caption.paragraph1')}
                            </Typography>
                        </ParagraphDots>
                        <ParagraphDots>
                            <Typography variant={{ _: 'h3', laptop: 'h2' }} as="p" color="grey">
                                {t('history.caption.paragraph2')}
                            </Typography>
                        </ParagraphDots>
                    </Box>
                </Card>
            </GridParent>
        </section>
    );
};

export default History;
