import { props as defaultProps } from '@styled-system/should-forward-prop';
import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';

import mediaQueries from '@theme/configs/mediaQueries';

import Box from '@atoms/Box';

const StyledBox = styled(Box).withConfig({
    shouldForwardProp: (prop) => ![...defaultProps, 'bulletColor'].includes(String(prop)),
})<{ bulletColor?: string }>`
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    height: 1rem;

    .bullet {
        display: flex;
        place-items: center;
        place-content: center;
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
        height: 1rem;
        width: 1rem;

        ${mediaQueries.laptopS} {
            height: 1rem;
            width: 1rem;
        }

        &:not(.swiper-pagination-bullet-active)::after {
            content: '';
            border: 2px solid ${({ bulletColor, theme }) => bulletColor ?? theme.colors.greyDark};
            display: inline-block;
            height: 0.7rem;
            width: 0.8rem;
            border-radius: 50%;
            text-align: center;
            line-height: 1rem;
            color: ${({ theme }) => theme.colors.white};
            background-color: transparent;
            transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
                background-color 0.2s ease-in-out, border 0.3s ease-in-out;
        }

        &:hover {
            cursor: pointer;

            &::after {
                background-color: ${({ theme }) => theme.colors.brandAccent};
                height: 0.75rem;
                width: 0.75rem;
                border: 0;
            }
        }

        span {
            display: none;
        }

        &.swiper-pagination-bullet-active {
            background-color: ${({ theme }) => theme.colors.brandAccent};
            border: 0;

            &.bullet-number {
                width: 1rem;
                height: 1rem;
                line-height: 2rem;
                text-align: center;

                span {
                    display: inline-block;
                }
            }
        }
    }
`;

const SliderPagination = (props: PropsWithChildren<any>, ref: ForwardedRef<any>) => {
    const { children, ...rest } = props;

    return (
        <StyledBox ref={ref} {...rest}>
            {children}
        </StyledBox>
    );
};

export default forwardRef(SliderPagination);
