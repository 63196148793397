// eslint-disable-next-line import/no-unresolved
import EnglishTranslations from '@app-i18n/translations/en.json';
import i18n from 'i18next';

i18n.init({
    resources: {
        en: {
            translation: EnglishTranslations,
        },
    },
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
    keySeparator: '.',
});

export default i18n;
