import { createContext } from 'react';

export type HeaderContextType = {
    hasWhiteBg: boolean;
    toggleWhiteHeader: () => void;
};

const HeaderContext = createContext<HeaderContextType>({
    hasWhiteBg: false,
    toggleWhiteHeader: () => {},
});

export default HeaderContext;
