import { hexToRGB } from '@utils/helpers';
import { ReactNode } from 'react';
import styled from 'styled-components';

import mediaQueries from '@theme/configs/mediaQueries';

import Box from '@atoms/Box';
import Typography from '@atoms/Typography';

const ReviewerAvatar = styled.div`
    left: 0;
    max-width: 9rem;
    position: absolute;
    top: 0;

    ${mediaQueries.tablet} {
        max-width: 14rem;
    }
`;

const CardWrapper = styled.div`
    padding: 1rem 0 2rem 1rem;
    position: relative;

    ${mediaQueries.tablet} {
        padding: 2rem 2rem 0;
    }
`;

const AuthorInfoWrapper = styled.div`
    ${mediaQueries.tablet} {
        min-height: 9rem;
    }

    ${mediaQueries.laptopS} {
        min-height: unset;
    }

    padding: 1.5rem 0 0.5rem 6rem;

    ${mediaQueries.tablet} {
        padding: 1.5rem 2.5rem 0.5rem 10rem;
    }

    ${mediaQueries.laptopS} {
        padding: 1.5rem 2.5rem 0 10rem;
    }
`;

const Card = styled(Box)`
    background-color: ${({ theme }) => `rgba(${hexToRGB(theme.colors.greyDark)}, 0.05)`};
    margin-left: 2.8rem;
`;

interface ReviewCardProps {
    authorImg: ReactNode;
    authorPosition: string;
    authorName: string;
    reviewText: string;
}

const ReviewCard = ({ authorImg, authorPosition, authorName, reviewText }: ReviewCardProps) => {
    const reviewTextPadding = {
        _: '0',
        laptopS: '0 2.5rem 3rem 10rem',
    };

    return (
        <CardWrapper>
            <Card
                p={{
                    _: '0 1.5rem 4rem',
                    tablet: '2rem 2.3rem 5rem 2.2rem',
                    laptopS: '2rem 2.3rem 2rem 2.2rem',
                }}
            >
                <Box maxWidth={{ _: '100%' }}>
                    <AuthorInfoWrapper>
                        <Typography variant="caption" color="brandAccent">
                            {authorPosition}
                        </Typography>
                        <Typography variant={{ _: 'h3', tablet: 'h2' }} color="grey" mb="1.1rem">
                            {authorName}
                        </Typography>
                    </AuthorInfoWrapper>
                    <Box p={reviewTextPadding}>
                        <Typography variant="paragraph" color="grey">
                            {reviewText}
                        </Typography>
                    </Box>
                </Box>
            </Card>
            <ReviewerAvatar>{authorImg}</ReviewerAvatar>
        </CardWrapper>
    );
};

export default ReviewCard;
