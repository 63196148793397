const zIndices = {
    stepUp: 1,
    stepBack: -1,
    header: 100,
    dropdown: 500,
    burgerMenu: 400,
    backdrop: 200,
};

export default zIndices;
