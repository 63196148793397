import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const PageContext = React.createContext({
    lang: 'en',
    originalPath: '/en',
});

export const PageContextProvider = ({ value, children }: any) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (value?.lang) {
            i18n.changeLanguage(value.lang);
        }
    }, [i18n, value.lang]);

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = () => React.useContext(PageContext);
