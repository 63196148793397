import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Typography from '@atoms/Typography';

const List = styled.ul`
  margin-top: 1rem;
}
    li {
        display: flex;
        justify-content: flex-end;

        a:hover span {
          color: ${({ theme }) => theme.colors.brandAccent}
    }
      
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
`;

export const Links = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Typography variant="caption" color="greyDark">
                {t('header.links')}
            </Typography>
            <List>
                <li>
                    <a href="/#">
                        <Typography variant="accent" color="brandBlack">
                            {t('header.CryoTHEC')}
                        </Typography>
                    </a>
                </li>
                <li>
                    <a href="/#">
                        <Typography variant="accent" color="brandBlack">
                            {t('header.THECApp')}
                        </Typography>
                    </a>
                </li>
            </List>
        </div>
    );
};
