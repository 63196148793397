import { props as defaultFilteredProps } from '@styled-system/should-forward-prop';
import { phoneNumber } from '@utils/appConfig';
import { motion } from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useHeaderContext } from '@hooks/useHeaderContext';

import Box from '@atoms/Box';
import Button from '@atoms/Button';
import Container from '@atoms/Container';
import Flex from '@atoms/Flex';
import { SmartphoneIcon } from '@atoms/Icons';
import { LogoDarkColoredIcon, LogoWhiteBgColorIcon } from '@atoms/Icons/logo';
import Typography from '@atoms/Typography';

import HamburgerMenu from '@molecules/HamburgerMenu';
import LanguageDropdown from '@molecules/LanguageDropdown';

const Wrapper = styled(motion.div).withConfig({
    shouldForwardProp: (prop) => ![...defaultFilteredProps, 'hasWhiteBg'].includes(String(prop)),
})<{ hasWhiteBg: boolean }>`
    padding: 0.5rem 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndices.header};

    ${({ hasWhiteBg }) =>
        hasWhiteBg &&
        `
        background-color: white;
    `};
`;

const Header = ({ motionConfig }: { motionConfig: Record<string, any> }): ReactElement => {
    const { tablet } = useBreakpoint();
    const { t } = useTranslation();
    const { hasWhiteBg } = useHeaderContext();

    return (
        <header>
            <Wrapper
                hasWhiteBg={hasWhiteBg}
                initial={motionConfig.initial}
                animate={motionConfig.animate}
            >
                <Container>
                    <Flex flexGrow={1} alignItems="center">
                        <Flex flexGrow={tablet ? 0 : 1}>
                            {hasWhiteBg ? <LogoWhiteBgColorIcon /> : <LogoDarkColoredIcon />}
                        </Flex>
                        {tablet && (
                            <>
                                <Flex flexGrow={1} justifyContent="flex-end">
                                    <a href="https://cryosculptor.com">
                                        <Typography
                                            variant="caption"
                                            color={hasWhiteBg ? 'brandBlack' : 'white'}
                                            hoverColor="brandAccent"
                                            mr="3rem"
                                        >
                                            {t('header.CryoTHEC')}
                                        </Typography>
                                    </a>
                                    <a href="/">
                                        <Typography
                                            variant="caption"
                                            color={hasWhiteBg ? 'brandBlack' : 'white'}
                                            hoverColor="brandAccent"
                                            mr="3rem"
                                        >
                                            {t('header.THECApp')}
                                        </Typography>
                                    </a>
                                </Flex>
                                <Button
                                    as="a"
                                    variant="secondary"
                                    variantTheme={hasWhiteBg ? 'light' : 'dark'}
                                    href={`tel:${phoneNumber}`}
                                >
                                    <Flex alignItems="center">
                                        {phoneNumber}
                                        <Box pl="2rem">
                                            <SmartphoneIcon />
                                        </Box>
                                    </Flex>
                                </Button>
                            </>
                        )}
                        {!tablet && (
                            <Box ml="2rem">
                                <HamburgerMenu />
                            </Box>
                        )}
                        {false && <LanguageDropdown language="en" originalPath="/" isDark />}
                    </Flex>
                </Container>
            </Wrapper>
        </header>
    );
};

export default Header;
