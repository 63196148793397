import { yupResolver } from '@hookform/resolvers/yup';
import { phoneRegExp } from '@utils/helpers';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    useNetlifyForm,
    NetlifyFormProvider,
    NetlifyFormComponent,
    Honeypot, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
} from 'react-netlify-forms';
import * as yup from 'yup';

import Box from '@atoms/Box';
import Button from '@atoms/Button';
import Flex from '@atoms/Flex';
import { Input, TextArea } from '@atoms/Form';
import Checkbox from '@atoms/Form/Checkbox';
import { ChatIcon } from '@atoms/Icons';
import Typography from '@atoms/Typography';

import CallUsBtn from '@molecules/Form/CallUsBtn';
import SuccessBlock from '@molecules/Form/SuccessBlock';

const defaultValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
    policy: false,
};

const Form = ({ title, ...rest }: { title?: string }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { t } = useTranslation();
    const schema = yup.object({
        formName: yup.string(),
        name: yup.string().required(t('form.required.name')).min(2, t('form.error.name')),
        email: yup.string().required(t('form.required.email')).email(t('form.error.email')),
        phone: yup
            .string()
            .matches(phoneRegExp, { message: t('form.error.phone'), excludeEmptyString: true }),
        message: yup.string().max(200, t('form.error.message')),
        policy: yup.boolean().oneOf([true], t('form.error.policy')),
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues,
        resolver: yupResolver(schema),
    });
    const netlify = useNetlifyForm({
        name: 'contact',
        honeypotName: 'bot-field',
    });

    const onSubmit = (data: any) => {
        netlify.handleSubmit(null, data);
        reset(defaultValues);
        setIsSubmitted(true);
    };

    useEffect(() => {
        const returnForm = setTimeout(() => {
            if (isSubmitted) {
                setIsSubmitted(false);
            }
        }, 5000);

        return () => clearTimeout(returnForm);
    }, [isSubmitted]);

    return (
        <Box minHeight="40.5rem" width="100%" display="flex" alignItems="center">
            <AnimatePresence mode="wait">
                {isSubmitted ? (
                    <SuccessBlock />
                ) : (
                    <Box
                        key="form2"
                        as={motion(Box)}
                        width="100%"
                        {...rest}
                        intitial={{ opacity: 0 }}
                        animate={{
                            opacity: 1,
                            transition: { duration: 0.5, when: 'beforeChildren' },
                        }}
                        exit={{ opacity: 0 }}
                    >
                        <NetlifyFormProvider {...netlify}>
                            <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
                                <Honeypot />
                                <Box
                                    display="flex"
                                    mb="1.25rem"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                    gridRowGap="2rem"
                                >
                                    <Typography variant="h2" color="white" mr="0.5rem">
                                        {t('form.heading')}
                                    </Typography>

                                    <CallUsBtn />
                                </Box>
                                <Box width="100%" mb="1rem">
                                    <Input
                                        {...register('name')}
                                        label={t('form.name')}
                                        type="text"
                                        error={errors?.name?.message}
                                    />
                                </Box>
                                <Flex gridGap="1rem" flexWrap={{ _: 'wrap', tabletS: 'nowrap' }}>
                                    <Box width={{ _: '100%', tabletS: '50%' }}>
                                        <Input
                                            {...register('email')}
                                            label={t('form.mail')}
                                            type="email"
                                            error={errors?.email?.message}
                                        />
                                    </Box>
                                    <Box width={{ _: '100%', tabletS: '50%' }}>
                                        <Input
                                            {...register('phone')}
                                            label={`${t('form.phone')}`}
                                            type="text"
                                            error={errors?.phone?.message}
                                        />
                                    </Box>
                                </Flex>
                                <Box mt="1rem">
                                    <TextArea
                                        {...register('message')}
                                        label={t('form.message')}
                                        rows={10}
                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    mt="1rem"
                                    flexWrap="wrap"
                                    gridGap="2rem"
                                >
                                    <Box width={{ _: '100%', tablet: 'unset' }}>
                                        <Checkbox
                                            {...register('policy')}
                                            error={errors?.policy?.message}
                                        >
                                            <Typography variant="caption" color="white">
                                                {t('form.agreement')}&nbsp;
                                                <Typography
                                                    as="span"
                                                    variant="caption"
                                                    color="brandAccent"
                                                >
                                                    {t('form.terms')}
                                                </Typography>
                                            </Typography>
                                        </Checkbox>
                                    </Box>
                                    <Button
                                        variant="primary"
                                        width={{ _: '100%', tablet: 'unset' }}
                                    >
                                        {t('form.submit')}
                                        <ChatIcon style={{ marginLeft: '1.4rem' }} />
                                    </Button>
                                </Box>
                            </NetlifyFormComponent>
                        </NetlifyFormProvider>
                    </Box>
                )}
            </AnimatePresence>
        </Box>
    );
};

export default Form;
