import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgLogoColor = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={101}
        height={40}
        viewBox="0 0 101 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_908_10978)">
            <path
                d="M17.326 19.8074C17.2441 19.6637 17.1254 19.5443 16.9821 19.4617C16.8388 19.379 16.6761 19.3361 16.5107 19.3372C16.3451 19.3361 16.1823 19.3791 16.0389 19.462C15.8956 19.5449 15.777 19.6645 15.6953 19.8085L11.8397 26.5046C11.7753 26.6152 11.6833 26.7071 11.5726 26.7713C11.4619 26.8355 11.3364 26.8698 11.2085 26.8707H10.2396C10.1115 26.8697 9.98593 26.8353 9.87524 26.7709C9.76454 26.7065 9.67255 26.6144 9.60834 26.5036L9.12652 25.6693C9.06188 25.5574 9.02786 25.4304 9.02786 25.3011C9.02786 25.1718 9.06188 25.0448 9.12652 24.9329L13.0127 18.2525C13.0938 18.1096 13.1363 17.948 13.136 17.7836C13.1357 17.6192 13.0926 17.4578 13.011 17.3151C12.9293 17.1725 12.812 17.0535 12.6704 16.9699C12.5289 16.8864 12.368 16.8411 12.2037 16.8386H11.2769C11.0931 16.8391 10.9128 16.888 10.7539 16.9803C10.5951 17.0726 10.4634 17.2052 10.3721 17.3646L6.07986 24.7782C5.9885 24.9375 5.94043 25.118 5.94043 25.3016C5.94043 25.4853 5.9885 25.6657 6.07986 25.825L7.94299 29.0663C8.03479 29.2253 8.16659 29.3575 8.32531 29.4498C8.48403 29.542 8.66414 29.5912 8.84773 29.5923L12.5898 29.5976C12.7734 29.5964 12.9535 29.5473 13.1122 29.455C13.2709 29.3628 13.4027 29.2305 13.4945 29.0715L17.7878 21.6558C17.8802 21.4959 17.9288 21.3145 17.9288 21.1298C17.9288 20.9452 17.8802 20.7637 17.7878 20.6038L17.326 19.8074Z"
                fill="#4052F8"
            />
            <path
                d="M22.1744 19.1246L30.7379 19.1373C30.9215 19.1362 31.1016 19.087 31.2603 18.9947C31.419 18.9025 31.5508 18.7703 31.6426 18.6112L33.5195 15.3679C33.6106 15.2087 33.6586 15.0284 33.6586 14.845C33.6586 14.6616 33.6106 14.4813 33.5195 14.3222L31.6553 11.0809C31.5635 10.9219 31.4317 10.7897 31.2729 10.6974C31.1142 10.6051 30.9341 10.556 30.7505 10.5549L22.186 10.5422C22.0022 10.5432 21.8219 10.5922 21.663 10.6845C21.5041 10.7768 21.3721 10.9091 21.2802 11.0682L20.8173 11.8709C20.7362 12.0143 20.694 12.1764 20.6947 12.3411C20.6954 12.5059 20.7391 12.6676 20.8214 12.8103C20.9038 12.9529 21.0219 13.0717 21.1642 13.1547C21.3064 13.2378 21.4679 13.2823 21.6326 13.2838L29.3513 13.2764C29.4794 13.2775 29.605 13.3119 29.7157 13.3763C29.8264 13.4407 29.9183 13.5328 29.9826 13.6436L30.4633 14.48C30.528 14.5919 30.562 14.7189 30.562 14.8482C30.562 14.9774 30.528 15.1044 30.4633 15.2164L29.9815 16.0485C29.917 16.1589 29.8249 16.2506 29.7142 16.3147C29.6036 16.3787 29.4781 16.4128 29.3503 16.4136L21.6263 16.3873C21.4612 16.3871 21.299 16.4303 21.1559 16.5127C21.0128 16.595 20.8939 16.7136 20.8112 16.8565C20.7285 16.9993 20.6848 17.1615 20.6846 17.3266C20.6844 17.4917 20.7276 17.6539 20.81 17.797L21.2708 18.6018C21.3629 18.76 21.4947 18.8915 21.6532 18.9832C21.8117 19.0749 21.9913 19.1237 22.1744 19.1246Z"
                fill="#4052F8"
            />
            <path
                d="M9.63242 3.09084C9.69676 2.98024 9.78881 2.88831 9.89949 2.82411C10.0102 2.75991 10.1357 2.72566 10.2636 2.72474H11.2283C11.3566 2.72525 11.4825 2.75955 11.5933 2.82419C11.7041 2.88883 11.796 2.98153 11.8596 3.09295L15.6963 9.79959C15.7779 9.94522 15.897 10.0663 16.0414 10.1501C16.1857 10.2339 16.3499 10.2775 16.5169 10.2762C16.6817 10.2771 16.8439 10.2343 16.9868 10.152C17.1296 10.0698 17.2481 9.95105 17.3301 9.80801L17.7951 9.00636C17.8874 8.84644 17.936 8.66502 17.936 8.48035C17.936 8.29568 17.8874 8.11427 17.7951 7.95434L13.5291 0.531271C13.4373 0.372271 13.3055 0.240061 13.1468 0.147782C12.9881 0.0555035 12.808 0.00636828 12.6244 0.00526011L8.88233 0C8.69874 0.00110817 8.51863 0.0502434 8.35991 0.142522C8.20119 0.234801 8.06938 0.367011 7.97759 0.526011L6.10183 3.76308C6.01047 3.92239 5.9624 4.10283 5.9624 4.28647C5.9624 4.47011 6.01047 4.65055 6.10183 4.80985L10.373 12.2371C10.4649 12.3963 10.5969 12.5286 10.7558 12.6209C10.9147 12.7132 11.0951 12.7622 11.2788 12.7631H12.2036C12.3684 12.7615 12.5299 12.7169 12.6721 12.6337C12.8144 12.5506 12.9325 12.4317 13.0148 12.2889C13.097 12.1461 13.1406 11.9843 13.1411 11.8195C13.1416 11.6547 13.0991 11.4926 13.0178 11.3492L9.1527 4.6573C9.08806 4.54535 9.05404 4.41836 9.05404 4.2891C9.05404 4.15983 9.08806 4.03284 9.1527 3.92089L9.63242 3.09084Z"
                fill="#4052F8"
            />
            <path
                d="M21.1775 4.16394L21.7929 3.09719C21.8571 2.98639 21.9491 2.89425 22.0598 2.82986C22.1705 2.76548 22.2961 2.73107 22.4241 2.73003H23.3857C23.5137 2.73107 23.6393 2.76548 23.75 2.82986C23.8607 2.89425 23.9527 2.98639 24.0169 3.09719L24.4977 3.93355C24.5623 4.0455 24.5963 4.17249 24.5963 4.30176C24.5963 4.43102 24.5623 4.55801 24.4977 4.66996L23.8812 5.74303C23.8004 5.88675 23.7585 6.04908 23.7597 6.21395C23.761 6.37882 23.8052 6.54051 23.8881 6.68303C23.971 6.82555 24.0897 6.94395 24.2324 7.02652C24.3751 7.10909 24.5369 7.15296 24.7018 7.15379H25.6086C25.7918 7.15231 25.9715 7.10301 26.1298 7.01075C26.2882 6.91849 26.4196 6.78647 26.5112 6.62778L27.5538 4.82671C27.645 4.66754 27.6929 4.48729 27.6929 4.30386C27.6929 4.12043 27.645 3.94018 27.5538 3.781L25.6896 0.537619C25.5984 0.37812 25.4667 0.245503 25.3079 0.153149C25.149 0.060796 24.9686 0.0119733 24.7849 0.0116078L21.0407 0.00634766C20.8571 0.00745583 20.677 0.056591 20.5183 0.14887C20.3596 0.241149 20.2278 0.373358 20.136 0.532359L19.0955 2.33027C19.0032 2.49019 18.9546 2.67161 18.9546 2.85628C18.9546 3.04095 19.0032 3.22236 19.0955 3.38229L19.5511 4.17341C19.6326 4.31762 19.7511 4.43745 19.8945 4.52049C20.0378 4.60353 20.2007 4.64678 20.3664 4.64577C20.5323 4.64509 20.6949 4.60006 20.8376 4.51534C20.9802 4.43062 21.0975 4.30929 21.1775 4.16394Z"
                fill="#94A3F7"
            />
            <path
                d="M6.39432 16.8892C6.3128 16.7439 6.19395 16.623 6.05005 16.5391C5.90616 16.4551 5.74244 16.4111 5.57584 16.4116H4.3292C4.20125 16.4107 4.07574 16.3764 3.96506 16.3122C3.85437 16.248 3.76232 16.1561 3.69798 16.0455L3.2109 15.2039C3.14626 15.0919 3.11224 14.9649 3.11224 14.8357C3.11224 14.7064 3.14626 14.5794 3.2109 14.4675L3.69167 13.6258C3.75615 13.5137 3.84902 13.4206 3.96094 13.3557C4.07285 13.2909 4.19986 13.2567 4.3292 13.2566H5.56638C5.73252 13.2562 5.89564 13.2122 6.03937 13.1288C6.1831 13.0455 6.30236 12.9258 6.38519 12.7818C6.46802 12.6378 6.5115 12.4745 6.51126 12.3084C6.51101 12.1422 6.46705 11.9791 6.3838 11.8353L5.9167 11.0463C5.82437 10.8864 5.69157 10.7535 5.53164 10.6612C5.37172 10.5689 5.19031 10.5203 5.00565 10.5203L2.91843 10.5266C2.73431 10.5271 2.55355 10.576 2.39423 10.6683C2.23491 10.7606 2.1026 10.8931 2.01054 11.0526L0.138991 14.3275C0.0466569 14.4875 -0.00195313 14.6689 -0.00195312 14.8535C-0.00195313 15.0382 0.0466569 15.2196 0.138991 15.3796L2.03263 18.6314C2.1247 18.7908 2.257 18.9233 2.41632 19.0156C2.57564 19.1079 2.7564 19.1568 2.94053 19.1574L5.033 19.1468C5.21677 19.1459 5.39709 19.0969 5.55601 19.0046C5.71493 18.9123 5.84691 18.78 5.93879 18.6208L6.39432 17.8339C6.47786 17.6905 6.52187 17.5275 6.52187 17.3616C6.52187 17.1956 6.47786 17.0326 6.39432 16.8892Z"
                fill="#94A3F7"
            />
            <path
                d="M100.994 27.5777V26.3152C100.994 25.4782 100.662 24.6754 100.07 24.0836C99.4782 23.4917 98.6754 23.1592 97.8384 23.1592H87.3182C86.4811 23.1592 85.6784 23.4917 85.0865 24.0836C84.4946 24.6754 84.1621 25.4782 84.1621 26.3152V36.8355C84.1621 37.6725 84.4946 38.4753 85.0865 39.0672C85.6784 39.659 86.4811 39.9915 87.3182 39.9915H97.8384C98.6754 39.9915 99.4782 39.659 100.07 39.0672C100.662 38.4753 100.994 37.6725 100.994 36.8355V35.5646C100.994 35.3972 100.928 35.2367 100.81 35.1183C100.691 34.9999 100.531 34.9334 100.363 34.9334H98.0488C97.8814 34.9334 97.7208 34.9999 97.6025 35.1183C97.4841 35.2367 97.4176 35.3972 97.4176 35.5646V35.9939C97.4176 36.2729 97.3068 36.5405 97.1095 36.7377C96.9122 36.935 96.6446 37.0459 96.3656 37.0459H88.791C88.512 37.0459 88.2444 36.935 88.0471 36.7377C87.8498 36.5405 87.739 36.2729 87.739 35.9939V27.1569C87.739 26.8779 87.8498 26.6103 88.0471 26.413C88.2444 26.2157 88.512 26.1048 88.791 26.1048H96.3656C96.6446 26.1048 96.9122 26.2157 97.1095 26.413C97.3068 26.6103 97.4176 26.8779 97.4176 27.1569V27.5777C97.4176 27.7451 97.4841 27.9056 97.6025 28.024C97.7208 28.1424 97.8814 28.2089 98.0488 28.2089H100.363C100.531 28.2089 100.691 28.1424 100.81 28.024C100.928 27.9056 100.994 27.7451 100.994 27.5777Z"
                fill="#181535"
            />
            <path
                d="M37.2417 23.1592H21.6717C21.5043 23.1592 21.3438 23.2257 21.2254 23.3441C21.107 23.4624 21.0405 23.623 21.0405 23.7904V25.4736C21.0405 25.641 21.107 25.8016 21.2254 25.92C21.3438 26.0383 21.5043 26.1048 21.6717 26.1048H27.6546V39.3687C27.6546 39.5361 27.7211 39.6967 27.8395 39.8151C27.9578 39.9335 28.1184 40 28.2858 40H30.6276C30.795 40 30.9556 39.9335 31.0739 39.8151C31.1923 39.6967 31.2588 39.5361 31.2588 39.3687V26.1048H37.2417C37.4091 26.1048 37.5696 26.0383 37.688 25.92C37.8064 25.8016 37.8729 25.641 37.8729 25.4736V23.7904C37.8729 23.623 37.8064 23.4624 37.688 23.3441C37.5696 23.2257 37.4091 23.1592 37.2417 23.1592Z"
                fill="#181535"
            />
            <path
                d="M79.3227 23.1593L63.7528 23.1509C63.5854 23.1509 63.4248 23.2174 63.3065 23.3358C63.1881 23.4541 63.1216 23.6147 63.1216 23.7821V39.3604C63.1216 39.5279 63.1881 39.6884 63.3065 39.8068C63.4248 39.9252 63.5854 39.9917 63.7528 39.9917H79.3227C79.4901 39.9917 79.6507 39.9252 79.7691 39.8068C79.8874 39.6884 79.9539 39.5279 79.9539 39.3604V37.6772C79.9539 37.5098 79.8874 37.3492 79.7691 37.2309C79.6507 37.1125 79.4901 37.046 79.3227 37.046H66.6985V33.0399H77.2187C77.3861 33.0399 77.5466 32.9734 77.665 32.855C77.7834 32.7366 77.8499 32.5761 77.8499 32.4087V30.7254C77.8499 30.558 77.7834 30.3975 77.665 30.2791C77.5466 30.1607 77.3861 30.0942 77.2187 30.0942H66.6985V26.105H79.3227C79.4901 26.105 79.6507 26.0385 79.7691 25.9201C79.8874 25.8017 79.9539 25.6412 79.9539 25.4737V23.7905C79.9539 23.6231 79.8874 23.4625 79.7691 23.3442C79.6507 23.2258 79.4901 23.1593 79.3227 23.1593Z"
                fill="#181535"
            />
            <path
                d="M58.2822 23.1509H55.9678C55.8003 23.1509 55.6398 23.2174 55.5214 23.3358C55.403 23.4541 55.3365 23.6147 55.3365 23.7821V30.0942H45.6579V23.7821C45.6579 23.6147 45.5914 23.4541 45.4731 23.3358C45.3547 23.2174 45.1941 23.1509 45.0267 23.1509H42.7123C42.5449 23.1509 42.3843 23.2174 42.2659 23.3358C42.1476 23.4541 42.0811 23.6147 42.0811 23.7821V39.352C42.0811 39.5194 42.1476 39.68 42.2659 39.7984C42.3843 39.9167 42.5449 39.9832 42.7123 39.9832H45.0267C45.1941 39.9832 45.3547 39.9167 45.4731 39.7984C45.5914 39.68 45.6579 39.5194 45.6579 39.352V33.0399H55.3365V39.352C55.3365 39.5194 55.403 39.68 55.5214 39.7984C55.6398 39.9167 55.8003 39.9832 55.9678 39.9832H58.2822C58.4496 39.9832 58.6102 39.9167 58.7285 39.7984C58.8469 39.68 58.9134 39.5194 58.9134 39.352V23.7821C58.9134 23.6147 58.8469 23.4541 58.7285 23.3358C58.6102 23.2174 58.4496 23.1509 58.2822 23.1509Z"
                fill="#181535"
            />
        </g>
        <defs>
            <clipPath id="clip0_908_10978">
                <rect width={100.994} height={40} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const Memo = memo(SvgLogoColor);
export default Memo;
