import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgBlockQuotes = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={97}
        height={76}
        viewBox="0 0 97 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M0.797363 45.3147H21.4122L7.66898 75.5245H28.2838L42.0271 45.3147V0H0.797363V45.3147Z"
            fill="#B6B9D6"
        />
        <path
            d="M55.7705 0V45.3147H76.3854L62.6421 75.5245H83.257L97.0002 45.3147V0H55.7705Z"
            fill="#B6B9D6"
        />
    </svg>
);

const Memo = memo(SvgBlockQuotes);
export default Memo;
