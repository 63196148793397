import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={16}
        height={13}
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M1 6.5L5.5 11L15 1.5" stroke="currentColor" strokeWidth={2} />
    </svg>
);

const Memo = memo(SvgCheck);
export default Memo;
