import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useTranslation } from 'react-i18next';

import BackgroundImage from '@atoms/BackgroundImage';
import Box from '@atoms/Box';
import GridChild from '@atoms/GridChild';
import GridParent from '@atoms/GridParent';
import { BlockQuotesIcon } from '@atoms/Icons';
import Typography from '@atoms/Typography';

import Form from '@molecules/Form';

const query = graphql`
    query {
        bg: file(relativePath: { eq: "cta_img.jpg" }) {
            childImageSharp {
                gatsbyImageData(aspectRatio: 4.475)
            }
        }
    }
`;

const CallToAction = () => {
    const { t } = useTranslation();
    const { bg } = useStaticQuery(query);
    const { tablet } = useBreakpoint();

    return (
        <section id="cta">
            <Box mt="18rem" overflow="hidden">
                <BackgroundImage
                    imageData={bg.childImageSharp.gatsbyImageData}
                    overlay="ctaGradient"
                    imgStyle={{ objectFit: 'cover' }}
                >
                    <GridParent
                        pt={{ _: '3rem', tablet: '6rem' }}
                        pb={{ _: '4rem', tablet: '8rem' }}
                        gridRowGap="3.5rem"
                    >
                        <GridChild
                            gridColumn={{ _: '1/13', laptop: '2/ 7', desktop: '3/ 7' }}
                            gridRow={1}
                        >
                            <Box position="relative">
                                <Box position="absolute" top={0} right={0}>
                                    <BlockQuotesIcon
                                        height={tablet ? '5rem' : '4rem'}
                                        style={{ opacity: '0.5' }}
                                    />
                                </Box>
                                <Typography variant="h2" color="greyDark" transformText="uppercase">
                                    {t('callToAction2.preHeading')}
                                </Typography>
                                <Typography
                                    variant="big"
                                    color="white"
                                    lineHeight="120%"
                                    transformText="uppercase"
                                    mt="1rem"
                                >
                                    {t('callToAction2.heading')}
                                </Typography>
                            </Box>
                        </GridChild>
                        <GridChild
                            gridColumn={{ _: '1/13', laptop: '7/ 12', desktop: '7/11' }}
                            gridRow={{ _: 2, laptop: 1 }}
                        >
                            <Box display="flex" alignItems="center" width="100%" height="100%">
                                <Form />
                            </Box>
                        </GridChild>
                    </GridParent>
                </BackgroundImage>
            </Box>
        </section>
    );
};

export default CallToAction;
