import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { debounce } from 'lodash';
import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import colors from '@theme/configs/colors';
import mediaQueries from '@theme/configs/mediaQueries';

import BackgroundImage from '@atoms/BackgroundImage';
import Box from '@atoms/Box';
import Typography from '@atoms/Typography';

const Wrapper = styled.div`
    flex: 1;
    height: 100%;
    min-height: 36rem;
    overflow: hidden;

    ${mediaQueries.desktop} {
        min-height: 72rem;
    }
`;

const Card = ({
    image,
    heading,
    caption,
    caption2,
    maxHeight,
    setMaxHeight,
}: {
    image: IGatsbyImageData;
    heading: string;
    caption: string;
    caption2?: string;
    maxHeight: number;
    setMaxHeight: (val: number) => void;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { laptopS } = useBreakpoint();

    useLayoutEffect(() => {
        const getSize = () => {
            const contentHeight = ref.current?.clientHeight || 700;
            const isContentBigger = contentHeight > maxHeight;

            if (isContentBigger) {
                setMaxHeight(contentHeight);
            }
        };

        window.addEventListener('resize', debounce(getSize, 10));

        () => window.removeEventListener('resize', getSize);
    }, [maxHeight, setMaxHeight]);

    return (
        <Wrapper>
            <BackgroundImage imageData={image} overlay="default">
                <Box
                    p={{ _: '14rem 4rem 5rem', tablet: '10rem 4rem 5rem' }}
                    width="100%"
                    height="100%"
                    display="flex"
                    maxWidth="51rem"
                    flexDirection="column"
                    justifyContent="flex-end"
                >
                    <Typography variant="h2" color="greyLt">
                        {heading}
                    </Typography>
                    <Box
                        width="6rem"
                        height="0.2rem"
                        backgroundColor={colors.brandAccent}
                        my="1rem"
                    />
                    <Box
                        maxHeight={laptopS && maxHeight ? maxHeight : undefined}
                        // height={(laptopS || !tablet) && maxHeight ? '100%' : 'min-content'}
                        height={{ _: '100%', tablet: 'min-content' }}
                    >
                        <div ref={ref}>
                            <Typography variant="paragraph" color="grey">
                                {caption}
                            </Typography>
                            {caption2 && (
                                <Typography variant="paragraph" color="grey" mt="1.2rem">
                                    {caption2}
                                </Typography>
                            )}
                        </div>
                    </Box>
                </Box>
            </BackgroundImage>
        </Wrapper>
    );
};

export default Card;
