import styled from 'styled-components';
import { layout, LayoutProps, size, SizeProps, variant } from 'styled-system';

import colors from '@theme/configs/colors';

import { fontsConfig } from '@atoms/Typography';

interface IButtonProps {
    variant?: 'primary' | 'secondary' | 'hollow';
    variantTheme?: 'light' | 'dark';
}

const variants = {
    light: variant({
        prop: 'variant',
        variants: {
            primary: {
                'border': 'none',
                'background-color': colors.brandAccent,
                'color': colors.white,
                '&:hover': {
                    'background-color': colors.blueDim,
                },
            },
            secondary: {
                'border': `1px solid ${colors.brandAccent}`,
                'background-color': 'transparent',
                'color': colors.brandAccent,
                '&:hover': {
                    'border': `1px solid ${colors.brandAccent}`,
                    'background-color': colors.brandAccent,
                    'color': colors.white,
                },
            },
            hollow: {
                'background-color': 'transparent',
                'color': colors.brandAccent,
                '&hover': {
                    color: colors.blueDim,
                },
            },
        },
    }),
    dark: variant({
        prop: 'variant',
        variants: {
            primary: {
                'border': 'none',
                'background-color': colors.white,
                'color': colors.brandAccent,
                '&:hover': {
                    'background-color': colors.blueDim,
                    'color': colors.white,
                },
            },
            secondary: {
                'border': `1px solid ${colors.white}`,
                'background-color': 'transparent',
                'color': colors.white,
                '&:hover': {
                    'border': `1px solid ${colors.brandAccent}`,
                    'background-color': colors.brandAccent,
                    'color': colors.white,
                },
            },
            hollow: {
                'background-color': 'transparent',
                'color': colors.white,
                '&hover': {
                    color: colors.greyDark,
                },
            },
        },
    }),
};

const Button = styled.button.attrs((props: IButtonProps & LayoutProps & SizeProps) => ({
    variant: props.variant || 'primary',
    variantTheme: props.variantTheme || 'light',
}))<IButtonProps & LayoutProps>`
    ${layout};
    ${size};
    ${{ ...fontsConfig.accent }};
    border-radius: 0.4rem;
    overflow: hidden;
    padding: 1.3rem 3rem;
    white-space: nowrap;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.32;
    }

    &:focus {
        outline: none;
    }

    ${({ variantTheme }) => variants[variantTheme]};
`;

export default Button;
