import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import Box from '@atoms/Box';
import { ParagraphDotsIcon } from '@atoms/Icons';

const StyledDots = styled(ParagraphDotsIcon)`
    left: 0;
    position: absolute;
    top: 0;
`;

const ParagraphDots = ({ children }: { children: ReactNode }): ReactElement => (
    <Box position="relative">
        <StyledDots />
        <Box pl="2.2rem" pt="2.2rem">
            {children}
        </Box>
    </Box>
);

export default ParagraphDots;
