import { props } from '@styled-system/should-forward-prop';
import { ComponentClass, ReactNode, ReactElement } from 'react';
import type { UseActiveViewportSizeReturn } from 'src/hooks/useActiveViewportSize';
import styled, { CSSObject } from 'styled-components';
import {
    variant as styledVariant,
    space,
    color as textColor,
    typography,
    SpaceProps,
    TypographyProps as TypographyBaseProps,
    ResponsiveValue,
    VerticalAlignProps,
    verticalAlign,
} from 'styled-system';

import { useActiveViewportSize } from '@hooks';

import { Device, deviceList } from '@theme/configs/breakpoints';
import { IColors } from '@theme/configs/colors';
import mediaQueries from '@theme/configs/mediaQueries';

export const FONT_FAMILY = 'Manrope, san-serif';
export const LORA_FONT_FAMILY = 'Lora, san-serif';
export const FONT_HEAVY = 800;
export const FONT_BOLD = 700;
export const FONT_REGULAR = 400;

export type Variant =
    | 'big'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'paragraph'
    | 'paragraph2'
    | 'caption'
    | 'captionSmall'
    | 'accent';

type TypographyVariant = Variant | ({ _: Variant } & { [key in Device]?: Variant });

type TransformText = 'capitalize' | 'lowercase' | 'uppercase';

export interface TypographyProps extends SpaceProps, TypographyBaseProps, VerticalAlignProps {
    variant?: TypographyVariant;
    color?: ResponsiveValue<string>;
    hoverColor?: ResponsiveValue<string>;
    as?: ComponentClass | string | null;
    children: ReactNode;
    transformText?: TransformText;
}

interface StyledTypographyProps extends TypographyProps {
    variant: TypographyVariant;
}

export const fontsConfig = {
    big: {
        fontFamily: LORA_FONT_FAMILY,
        fontSize: '4.8rem',
        fontStyle: 'italic',
        fontWeight: FONT_REGULAR,
        [mediaQueries.tablet]: {
            fontSize: '10.4rem',
        },
    },
    h1: {
        fontFamily: FONT_FAMILY,
        fontSize: '3.6rem',
        fontWeight: FONT_HEAVY,
        [mediaQueries.tablet]: {
            fontSize: '5.6rem',
        },
    },
    h2: {
        fontFamily: FONT_FAMILY,
        fontSize: '2.7rem',
        fontWeight: FONT_HEAVY,
        [mediaQueries.tablet]: {
            fontSize: '3.6rem',
        },
    },
    h3: {
        fontFamily: FONT_FAMILY,
        fontSize: '2.1rem',
        fontWeight: FONT_HEAVY,
        [mediaQueries.tablet]: {
            fontSize: '2.8rem',
        },
    },
    paragraph: {
        fontFamily: FONT_FAMILY,
        fontSize: '1.6rem',
        fontWeight: FONT_REGULAR,
        [mediaQueries.tablet]: {
            fontSize: '1.8rem',
        },
    },
    paragraph2: {
        fontFamily: FONT_FAMILY,
        fontSize: '1.4rem',
        fontWeight: FONT_REGULAR,
        [mediaQueries.tablet]: {
            fontSize: '1.6rem',
        },
    },
    caption: {
        fontFamily: FONT_FAMILY,
        fontSize: '1.2rem',
        fontWeight: FONT_REGULAR,
        [mediaQueries.tablet]: {
            fontSize: '1.4rem',
        },
    },
    captionSmall: {
        fontFamily: FONT_FAMILY,
        fontSize: '1rem',
        fontWeight: FONT_REGULAR,
        [mediaQueries.tablet]: {
            fontSize: '1.2rem',
        },
    },
    accent: {
        fontFamily: FONT_FAMILY,
        fontSize: '1.6rem',
        fontWeight: FONT_BOLD,
        [mediaQueries.tablet]: {
            fontSize: '1.8rem',
        },
    },
};

const typographyVariant = styledVariant({
    prop: 'variant',
    variants: fontsConfig,
});

const getAsProp = (
    variant: TypographyVariant,
    activeViewportSize: UseActiveViewportSizeReturn,
): string => {
    const variantMap = {
        big: 'p',
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        paragraph: 'p',
        paragraph2: 'p',
        caption: 'p',
        captionSmall: 'p',
        accent: 'span',
    };

    // Variant is of simple string variant="h1" approach
    if (typeof variant === 'string') {
        return variantMap[variant];
    }

    // Variant is of complex object type variant={{ _: 'h2', laptop: 'h1' }}
    // Variant has key of current activeViewportSize
    if (variant[activeViewportSize]) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return variantMap[variant[activeViewportSize]!];
    }

    // Variant does not contain activeViewportSize key. Return the closest one.
    let closestVariantDevice = deviceList
        .slice(
            0,
            deviceList.findIndex((device) => device === activeViewportSize),
        )
        .reverse()
        .find((device) => !!Object.prototype.hasOwnProperty.call(variant, device));

    // Variant does not contain any closest key from deviceList. Return default _ value.
    if (!closestVariantDevice) {
        closestVariantDevice = 'laptopS';
    }

    return variantMap[variant[closestVariantDevice]!];
};

const StyledTypography = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        ![...props, 'color', 'transformText', 'hoverColor', 'isActive'].includes(String(prop)),
})<StyledTypographyProps>`
    margin: 0;
    padding: 0;
    text-decoration: none;
    line-height: 130%;
    ${typographyVariant};
    ${textColor};
    ${space};
    ${typography};
    ${verticalAlign};

    ${({ transformText }: { transformText?: TransformText }) =>
        transformText ? `text-transform: ${transformText};` : null}

    ${({ theme, hoverColor }) =>
        hoverColor &&
        `  
            &:hover {
                color: ${theme.colors[hoverColor as IColors]};
            }
    `}
`;

const Typography = ({
    variant = 'paragraph',
    color = 'black',
    hoverColor,
    as = null,
    children,
    transformText,
    ...rest
}: TypographyProps & { style?: CSSObject }): ReactElement => {
    const activeViewportSize = useActiveViewportSize();
    const asPropFromVariant = getAsProp(variant, activeViewportSize);

    return (
        <StyledTypography
            variant={variant}
            transformText={transformText}
            color={color}
            hoverColor={hoverColor}
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            as={as || asPropFromVariant}
            {...rest}
        >
            {children}
        </StyledTypography>
    );
};

export default Typography;
