import { phoneNumber } from '@utils/appConfig';
import { getClampValue, hexToRGB } from '@utils/helpers';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { useHeaderContext } from '@hooks/useHeaderContext';

import colors from '@theme/configs/colors';

import BackgroundImage from '@atoms/BackgroundImage';
import Box from '@atoms/Box';
import Button from '@atoms/Button';
import Flex from '@atoms/Flex';
import { ChatIcon, SmartphoneIcon } from '@atoms/Icons';
import { LogoGreyFullIcon } from '@atoms/Icons/logo';
import { FacebookIcon, InstagramIcon, LinkedInIcon, YouTubeIcon } from '@atoms/Icons/socials';
import Typography from '@atoms/Typography';

const query = graphql`
    query {
        bg: file(relativePath: { eq: "heroBanner_bg.jpg" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;

const SocialsWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    opacity: 0.5;

    svg {
        fill: ${({ theme }) => theme.colors.white};
        z-index: 999;
    }
`;

const Socials = () => (
    <SocialsWrapper>
        <Box backgroundColor={colors.white} width={2} height="4.3rem" mx="0.5rem" mb="1.5rem" />
        <Button variant="hollow" variantTheme="dark" as="a" href="/#">
            <InstagramIcon />
        </Button>
        <Button variant="hollow" variantTheme="dark" as="a" href="/#">
            <YouTubeIcon />
        </Button>
        <Button variant="hollow" variantTheme="dark" as="a" href="/#">
            <LinkedInIcon />
        </Button>
        <Button variant="hollow" variantTheme="dark" as="a" href="/#">
            <FacebookIcon />
        </Button>
    </SocialsWrapper>
);

const HeroBanner = () => {
    const { t } = useTranslation();
    const { bg } = useStaticQuery(query);
    const { tabletS, tablet } = useBreakpoint();
    const { hasWhiteBg, toggleWhiteHeader } = useHeaderContext();

    const toggleHeaderBackground = (inView: boolean) => {
        if ((inView && hasWhiteBg) || (!inView && !hasWhiteBg)) {
            toggleWhiteHeader();
        }
    };
    const { ref } = useInView({ onChange: toggleHeaderBackground });

    return (
        <section>
            <Box position="relative" ref={ref} overflowX="hidden">
                {tablet && (
                    <Box
                        position="absolute"
                        left={{ _: '1.5rem' }}
                        top={0}
                        height="100%"
                        display="flex"
                        alignItems="center"
                        zIndex={1}
                    >
                        <Socials />
                    </Box>
                )}
                <Box
                    pt="9.4rem"
                    ml={
                        tablet
                            ? getClampValue('100px', '255px', '2560px')
                            : getClampValue('15px', '255px', '2560px')
                    }
                >
                    <Box width="100%" height="100%" maxHeight="134.5rem" position="relative">
                        <Box
                            backgroundColor={colors.black}
                            width="200%"
                            height="200%"
                            left="-50%"
                            bottom="9.4rem"
                            maxHeight="131.4rem"
                            position="absolute"
                        ></Box>
                        <BackgroundImage
                            imageData={bg.childImageSharp.gatsbyImageData}
                            imgPosition="35% center"
                            style={{ maxHeight: '98.5rem' }}
                        >
                            <StaticImage
                                src="../../../images/heroBanner_symbol.png"
                                alt="man looking in to the sky"
                                layout="constrained"
                                style={{
                                    gridArea: '1/1',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    maxWidth: '50%',
                                    minWidth: '28rem',
                                    opacity: 0.25,
                                    pointerEvents: 'none',
                                }}
                            />

                            <Box
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                height="100%"
                                p={{
                                    _: '3rem 1.5rem 4rem 2.6rem',
                                    tablet: '8.5rem 4.5rem 22.6rem 6rem',
                                }}
                            >
                                <Box maxWidth="75rem" textAlign="left" zIndex={1}>
                                    <div>
                                        <LogoGreyFullIcon
                                            width={tabletS ? '100%' : '16.8rem'}
                                            style={{ height: tabletS ? 'auto' : '4.7rem' }}
                                        />
                                    </div>
                                    <Typography
                                        variant={{ _: 'h3', tabletS: 'h2' }}
                                        color="white"
                                        mt="2.5rem"
                                    >
                                        {t('heroBanner.caption')}
                                    </Typography>
                                    <Box
                                        p={{ _: '1rem 2rem', tablet: '2rem 4rem' }}
                                        backgroundColor={`rgba(${hexToRGB(
                                            colors.brandBlack,
                                        )}, 0.8)`}
                                        mt={{ _: '2rem', tablet: '4rem' }}
                                    >
                                        <Typography
                                            variant={{ _: 'accent', tablet: 'h3' }}
                                            color="white"
                                        >
                                            {t('heroBanner.callToAction')}
                                        </Typography>
                                        <Typography
                                            variant={{ _: 'accent', tablet: 'h3' }}
                                            color="white"
                                        >
                                            {t('heroBanner.callToAction2')}
                                        </Typography>
                                        <Flex
                                            gridGap="1rem"
                                            flexWrap={{ _: 'wrap', tablet: 'nowrap' }}
                                            mt="2rem"
                                        >
                                            <Button
                                                as="a"
                                                href={`tel:${phoneNumber}`}
                                                width="100%"
                                                variantTheme="dark"
                                            >
                                                <Flex
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    gridGap="0.6rem"
                                                >
                                                    {t('heroBanner.makeACall')}
                                                    <SmartphoneIcon />
                                                </Flex>
                                            </Button>
                                            <Button
                                                as="a"
                                                href="#cta"
                                                width="100%"
                                                variantTheme="dark"
                                                variant="secondary"
                                            >
                                                <Flex
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    gridGap="0.6rem"
                                                >
                                                    {t('heroBanner.writeMessage')}
                                                    <ChatIcon />
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Box>
                            </Box>
                        </BackgroundImage>
                    </Box>
                </Box>
            </Box>
        </section>
    );
};

export default HeroBanner;
