import i18next from '@app-i18n/i18next';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactElement, ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';

import HeaderProvider from '@providers/HeaderProvider';
import ThemeProvider from '@providers/ThemeProvider';

import GlobalStyle from '@theme/GlobalStyles';

import Footer from '@organisms/Footer';
import Header from '@organisms/Header';

type LayoutProps = {
    children: ReactNode;
};

const duration = 0.5;

const variants = {
    initial: {
        opacity: 0,
        x: '100%',
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        transition: {
            duration,
            delay: duration,
            when: 'beforeChildren',
        },
    },
    exit: {
        opacity: 0,
        y: 200,
        transition: { duration },
    },
};

const Layout = ({ children }: LayoutProps): ReactElement => (
    <I18nextProvider i18n={i18next}>
        <ThemeProvider>
            <HeaderProvider>
                <>
                    <GlobalStyle />
                    <AnimatePresence>
                        <Header motionConfig={variants} />
                        <motion.main
                            key="main"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                        >
                            {children}
                        </motion.main>
                    </AnimatePresence>
                    <Footer />
                </>
            </HeaderProvider>
        </ThemeProvider>
    </I18nextProvider>
);

export default Layout;
