import styled from 'styled-components';

const SocialLinkWrapper = styled.div`
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.greyDark};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.greyDark};
    display: flex;
    height: 5rem;
    justify-content: center;
    vertical-align: middle;
    width: 5rem;

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.brandAccent};
        color: ${({ theme }) => theme.colors.brandAccent};
        cursor: pointer;
    }
`;

export default SocialLinkWrapper;
