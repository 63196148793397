import { ReactElement } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import theme from '@theme/configs';

const ThemeProvider = ({ children }: { children: ReactElement }): ReactElement => (
    <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
);

export default ThemeProvider;
