import styled from 'styled-components';
import {
    flexbox, FlexboxProps, ResponsiveValue, space, SpaceProps,
} from 'styled-system';

const Flex = styled.div<FlexboxProps & SpaceProps & { gridGap?: ResponsiveValue<string> }>`
    display: flex;
    grid-gap: ${({ gridGap }) => gridGap ?? undefined};
    ${flexbox};
    ${space};
`;

export default Flex;
