import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgMailEnvelope = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={31}
        height={23}
        viewBox="0 0 31 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.12012 1.87003C5.24012 5.36003 12.4701 11.49 14.5901 13.4C14.7029 13.5198 14.8382 13.6161 14.9883 13.6834C15.1384 13.7508 15.3003 13.7878 15.4647 13.7925C15.6292 13.7971 15.7929 13.7692 15.9465 13.7105C16.1002 13.6517 16.2407 13.5632 16.3601 13.45V13.4C18.4901 11.49 25.7101 5.35003 29.8301 1.87003C29.9527 1.7635 30.0302 1.61434 30.0469 1.45279C30.0636 1.29125 30.0183 1.12937 29.9201 1.00001C29.6867 0.690088 29.3846 0.438498 29.0376 0.264989C28.6906 0.0914806 28.3081 0.000777503 27.9201 5.02563e-06H2.9201C2.53185 -0.000774986 2.14881 0.0892525 1.80154 0.262883C1.45428 0.436514 1.15243 0.688937 0.920104 1.00001C0.824842 1.12864 0.781251 1.28832 0.797912 1.44751C0.814572 1.60671 0.89027 1.7539 1.0101 1.86002L1.12012 1.87003Z"
            fill="#4052F8"
        />
        <path
            d="M30.13 3.76005C30.0231 3.70946 29.9039 3.69103 29.7867 3.70701C29.6695 3.72299 29.5595 3.77268 29.47 3.85005C24.9 7.72005 19.07 12.6801 17.24 14.3301C16.7696 14.7782 16.1448 15.0281 15.495 15.0281C14.8453 15.0281 14.2205 14.7782 13.75 14.3301C11.8 12.5701 5.25005 7.01005 1.53005 3.85005C1.40181 3.74348 1.23704 3.69119 1.07082 3.70426C0.904599 3.71734 0.750035 3.79475 0.640035 3.92005C0.549915 4.0378 0.500738 4.18178 0.50002 4.33006V20.04C0.498698 20.3687 0.562474 20.6944 0.687642 20.9983C0.812811 21.3022 0.996919 21.5784 1.22933 21.8108C1.46174 22.0432 1.73786 22.2273 2.04177 22.3525C2.34568 22.4776 2.67135 22.5414 3.00002 22.54H28C28.6631 22.54 29.299 22.2767 29.7678 21.8078C30.2367 21.339 30.5 20.7031 30.5 20.04V4.33006C30.5018 4.20906 30.4676 4.09025 30.4018 3.98875C30.3359 3.88725 30.2413 3.80765 30.13 3.76005Z"
            fill="#4052F8"
        />
    </svg>
);

const Memo = memo(SvgMailEnvelope);
export default Memo;
