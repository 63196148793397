export const supportedLanguages = ['en'];

export const socialsLinks = {
    facebook: '#',
    instagram: '#',
    youtube: '#',
    linkedin: '#',
};

export const phoneNumber = '+467 223 86 841';

export const EMAIL = 'info@thec.net';

export const address = '';
