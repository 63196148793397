export const getClampValue = (min: string, max: string, container = '2053px'): string =>
    `clamp(${min}, calc(${parseInt(max, 10)} / ${parseInt(container, 10)} * 100vw) , ${max})`;

export const hexToRGB = (hex: string): string => {
    if (hex.length === 3) {
        return `${parseInt(hex[1], 16)}, ${parseInt(hex[2], 16)}, ${parseInt(hex[3], 16)}`;
    }

    // eslint-disable-next-line sonarjs/no-nested-template-literals
    return `${parseInt(`${hex[1]}${hex[2]}`, 16)}, ${parseInt(
        `${hex[3]}${hex[4]}`,
        16,
        // eslint-disable-next-line sonarjs/no-nested-template-literals
    )}, ${parseInt(`${hex[5]}${hex[6]}`, 16)}`;
};

export const phoneRegExp =
    /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/g;
