import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgLinkedIn = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M5.42954 8.00342H0.474121V24H5.42954V8.00342Z" fill="currentColor" />
        <path
            d="M2.92684 5.90412C3.31385 5.9028 3.6968 5.82528 4.05384 5.67596C4.41088 5.52663 4.73502 5.30844 5.00774 5.03384C5.28046 4.75925 5.49642 4.43362 5.6433 4.07557C5.79017 3.71751 5.86509 3.33403 5.86376 2.94703C5.86509 2.5605 5.7901 2.17752 5.6431 1.82003C5.4961 1.46254 5.27998 1.13757 5.00713 0.863783C4.73427 0.589995 4.41007 0.372756 4.05308 0.224531C3.6961 0.0763065 3.31337 -2.28224e-06 2.92684 0C2.54116 0.00132309 2.15951 0.0786087 1.80369 0.227426C1.44787 0.376244 1.12485 0.5937 0.853067 0.867356C0.581285 1.14101 0.366067 1.46551 0.219698 1.82234C0.073329 2.17917 -0.0013101 2.56134 1.75442e-05 2.94703C-0.00267148 3.72688 0.303849 4.47602 0.852451 5.0303C1.40105 5.58458 2.14699 5.89879 2.92684 5.90412Z"
            fill="currentColor"
        />
        <path
            d="M13.3529 15.6031C13.3529 13.3525 14.3621 12.0102 16.3806 12.0102C18.1973 12.0102 19.0753 13.302 19.0753 15.6031V24H24.0005V13.9075C24.0005 9.62831 21.5782 7.54927 18.1771 7.54927C17.2144 7.53557 16.2646 7.77204 15.4208 8.23555C14.5769 8.69906 13.8678 9.37372 13.3629 10.1935V8.00344H8.60938V24H13.3629L13.3529 15.6031Z"
            fill="currentColor"
        />
    </svg>
);

const Memo = memo(SvgLinkedIn);
export default Memo;
