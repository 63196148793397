import { motion } from 'framer-motion';
import React, { Suspense } from 'react';

import colors from '@theme/configs/colors';

import Box from '@atoms/Box';
import { LogoColorIcon } from '@atoms/Icons/logo';

import Layout from '@organisms/Layout';

import CallToAction from '@templates/CallToAction';
import HeroBanner from '@templates/HeroBanner';
import History from '@templates/History';
import OurFamily from '@templates/OurFamily';
import PhotoCarousel from '@templates/PhotoCarousel';
import Pros from '@templates/Pros';
import ProsCards from '@templates/ProsCards';
import Reviews from '@templates/Reviews';
import SEO from '@templates/SEO';

const LoadingScreen = () => (
    <Box
        key="layout"
        as={motion(Box)}
        layout
        width="100vw"
        height="100vh"
        backgroundColor={colors.grey}
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <LogoColorIcon style={{ height: '6vh', width: 'auto' }} />
    </Box>
);

const IndexPage = () => (
    <Layout>
        <SEO />
        <Suspense fallback={<LoadingScreen />}>
            <HeroBanner />
            <History />
            <Pros />
            <ProsCards />
            <CallToAction />
            <PhotoCarousel />
            <Reviews />
            <OurFamily />
        </Suspense>
    </Layout>
);

export default IndexPage;
