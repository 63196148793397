import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgLogoDarkColored = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={101}
        height={40}
        viewBox="0 0 101 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_902_14230)">
            <path
                d="M17.326 19.8074C17.2441 19.6637 17.1254 19.5443 16.9821 19.4617C16.8388 19.379 16.6761 19.3361 16.5107 19.3372C16.3451 19.3361 16.1823 19.3791 16.0389 19.462C15.8956 19.5449 15.777 19.6645 15.6953 19.8085L11.8397 26.5046C11.7753 26.6152 11.6833 26.7071 11.5726 26.7713C11.4619 26.8355 11.3364 26.8698 11.2085 26.8707H10.2396C10.1115 26.8697 9.98593 26.8353 9.87524 26.7709C9.76454 26.7065 9.67255 26.6144 9.60834 26.5036L9.12652 25.6693C9.06188 25.5574 9.02786 25.4304 9.02786 25.3011C9.02786 25.1718 9.06188 25.0448 9.12652 24.9329L13.0127 18.2525C13.0938 18.1096 13.1363 17.948 13.136 17.7836C13.1357 17.6192 13.0926 17.4578 13.011 17.3151C12.9293 17.1725 12.812 17.0535 12.6704 16.9699C12.5289 16.8864 12.368 16.8411 12.2037 16.8386H11.2769C11.0931 16.8391 10.9128 16.888 10.7539 16.9803C10.5951 17.0726 10.4634 17.2052 10.3721 17.3646L6.07986 24.7782C5.9885 24.9375 5.94043 25.118 5.94043 25.3016C5.94043 25.4853 5.9885 25.6657 6.07986 25.825L7.94299 29.0663C8.03479 29.2253 8.16659 29.3575 8.32531 29.4498C8.48403 29.542 8.66414 29.5912 8.84773 29.5923L12.5898 29.5976C12.7734 29.5964 12.9535 29.5473 13.1122 29.455C13.2709 29.3628 13.4027 29.2305 13.4945 29.0715L17.7878 21.6558C17.8802 21.4959 17.9288 21.3145 17.9288 21.1298C17.9288 20.9452 17.8802 20.7637 17.7878 20.6038L17.326 19.8074Z"
                fill="#4052F8"
            />
            <path
                d="M22.1744 19.1248L30.7379 19.1374C30.9215 19.1363 31.1016 19.0871 31.2603 18.9949C31.419 18.9026 31.5508 18.7704 31.6426 18.6114L33.5195 15.368C33.6106 15.2088 33.6586 15.0286 33.6586 14.8451C33.6586 14.6617 33.6106 14.4815 33.5195 14.3223L31.6553 11.081C31.5635 10.922 31.4317 10.7898 31.2729 10.6975C31.1142 10.6052 30.9341 10.5561 30.7505 10.555L22.186 10.5424C22.0022 10.5433 21.8219 10.5923 21.663 10.6846C21.5041 10.7769 21.3721 10.9092 21.2802 11.0684L20.8173 11.8711C20.7362 12.0145 20.694 12.1765 20.6947 12.3413C20.6954 12.506 20.7391 12.6677 20.8214 12.8104C20.9038 12.9531 21.0219 13.0718 21.1642 13.1549C21.3064 13.2379 21.4679 13.2824 21.6326 13.2839L29.3513 13.2766C29.4794 13.2776 29.605 13.312 29.7157 13.3764C29.8264 13.4408 29.9183 13.5329 29.9826 13.6437L30.4633 14.4801C30.528 14.592 30.562 14.719 30.562 14.8483C30.562 14.9776 30.528 15.1045 30.4633 15.2165L29.9815 16.0486C29.917 16.1591 29.8249 16.2508 29.7142 16.3148C29.6036 16.3788 29.4781 16.4129 29.3503 16.4137L21.6263 16.3874C21.4612 16.3872 21.299 16.4304 21.1559 16.5128C21.0128 16.5951 20.8939 16.7137 20.8112 16.8566C20.7285 16.9994 20.6848 17.1616 20.6846 17.3267C20.6844 17.4918 20.7276 17.654 20.81 17.7971L21.2708 18.6019C21.3629 18.7602 21.4947 18.8916 21.6532 18.9833C21.8117 19.075 21.9913 19.1238 22.1744 19.1248Z"
                fill="#4052F8"
            />
            <path
                d="M9.63291 3.09084C9.69725 2.98024 9.7893 2.88831 9.89998 2.82411C10.0107 2.75991 10.1362 2.72566 10.2641 2.72474H11.2288C11.3571 2.72525 11.483 2.75955 11.5938 2.82419C11.7046 2.88883 11.7965 2.98153 11.86 3.09295L15.6968 9.79959C15.7783 9.94522 15.8975 10.0663 16.0419 10.1501C16.1862 10.2339 16.3504 10.2775 16.5173 10.2762C16.6822 10.2771 16.8444 10.2343 16.9872 10.152C17.1301 10.0698 17.2486 9.95105 17.3306 9.80801L17.7956 9.00636C17.8879 8.84644 17.9365 8.66502 17.9365 8.48035C17.9365 8.29568 17.8879 8.11427 17.7956 7.95434L13.5296 0.531271C13.4378 0.372271 13.306 0.240061 13.1473 0.147782C12.9886 0.0555035 12.8085 0.00636828 12.6249 0.00526011L8.88282 0C8.69922 0.00110817 8.51912 0.0502434 8.3604 0.142522C8.20168 0.234801 8.06987 0.367011 7.97808 0.526011L6.10232 3.76308C6.01096 3.92239 5.96289 4.10283 5.96289 4.28647C5.96289 4.47011 6.01096 4.65055 6.10232 4.80985L10.3735 12.2371C10.4654 12.3963 10.5974 12.5286 10.7563 12.6209C10.9152 12.7132 11.0956 12.7622 11.2793 12.7631H12.2041C12.3688 12.7615 12.5304 12.7169 12.6726 12.6337C12.8149 12.5506 12.933 12.4317 13.0152 12.2889C13.0975 12.1461 13.141 11.9843 13.1416 11.8195C13.1421 11.6547 13.0996 11.4926 13.0183 11.3492L9.15319 4.6573C9.08855 4.54535 9.05453 4.41836 9.05453 4.2891C9.05453 4.15983 9.08855 4.03284 9.15319 3.92089L9.63291 3.09084Z"
                fill="#4052F8"
            />
            <path
                d="M21.177 4.16394L21.7924 3.09719C21.8566 2.98639 21.9486 2.89425 22.0593 2.82986C22.17 2.76548 22.2956 2.73107 22.4236 2.73003H23.3852C23.5132 2.73107 23.6388 2.76548 23.7495 2.82986C23.8602 2.89425 23.9522 2.98639 24.0164 3.09719L24.4972 3.93355C24.5618 4.0455 24.5958 4.17249 24.5958 4.30176C24.5958 4.43102 24.5618 4.55801 24.4972 4.66996L23.8807 5.74303C23.7999 5.88675 23.758 6.04908 23.7593 6.21395C23.7605 6.37882 23.8047 6.54051 23.8876 6.68303C23.9705 6.82555 24.0892 6.94395 24.2319 7.02652C24.3746 7.10909 24.5364 7.15296 24.7013 7.15379H25.6081C25.7914 7.15231 25.971 7.10301 26.1293 7.01075C26.2877 6.91849 26.4191 6.78647 26.5108 6.62778L27.5533 4.82671C27.6445 4.66754 27.6924 4.48729 27.6924 4.30386C27.6924 4.12043 27.6445 3.94018 27.5533 3.781L25.6891 0.537619C25.5979 0.37812 25.4662 0.245503 25.3074 0.153149C25.1485 0.060796 24.9681 0.0119733 24.7844 0.0116078L21.0402 0.00634766C20.8566 0.00745583 20.6765 0.056591 20.5178 0.14887C20.3591 0.241149 20.2273 0.373358 20.1355 0.532359L19.095 2.33027C19.0027 2.49019 18.9541 2.67161 18.9541 2.85628C18.9541 3.04095 19.0027 3.22236 19.095 3.38229L19.5506 4.17341C19.6321 4.31762 19.7506 4.43745 19.894 4.52049C20.0373 4.60353 20.2002 4.64678 20.3659 4.64577C20.5318 4.64509 20.6945 4.60006 20.8371 4.51534C20.9797 4.43062 21.0971 4.30929 21.177 4.16394Z"
                fill="#94A3F7"
            />
            <path
                d="M6.39432 16.8892C6.3128 16.7439 6.19395 16.623 6.05005 16.5391C5.90616 16.4551 5.74244 16.4111 5.57584 16.4116H4.3292C4.20125 16.4107 4.07574 16.3764 3.96506 16.3122C3.85437 16.248 3.76232 16.1561 3.69798 16.0455L3.2109 15.2039C3.14626 15.0919 3.11224 14.9649 3.11224 14.8357C3.11224 14.7064 3.14626 14.5794 3.2109 14.4675L3.69167 13.6258C3.75615 13.5137 3.84902 13.4206 3.96094 13.3557C4.07285 13.2909 4.19986 13.2567 4.3292 13.2566H5.56638C5.73252 13.2562 5.89564 13.2122 6.03937 13.1288C6.1831 13.0455 6.30236 12.9258 6.38519 12.7818C6.46802 12.6378 6.5115 12.4745 6.51126 12.3084C6.51101 12.1422 6.46705 11.9791 6.3838 11.8353L5.9167 11.0463C5.82437 10.8864 5.69157 10.7535 5.53164 10.6612C5.37172 10.5689 5.19031 10.5203 5.00565 10.5203L2.91843 10.5266C2.73431 10.5271 2.55355 10.576 2.39423 10.6683C2.23491 10.7606 2.1026 10.8931 2.01054 11.0526L0.138991 14.3275C0.0466569 14.4875 -0.00195313 14.6689 -0.00195312 14.8535C-0.00195313 15.0382 0.0466569 15.2196 0.138991 15.3796L2.03263 18.6314C2.1247 18.7908 2.257 18.9233 2.41632 19.0156C2.57564 19.1079 2.7564 19.1568 2.94053 19.1574L5.033 19.1468C5.21677 19.1459 5.39709 19.0969 5.55601 19.0046C5.71493 18.9123 5.84691 18.78 5.93879 18.6208L6.39432 17.8339C6.47786 17.6905 6.52187 17.5275 6.52187 17.3616C6.52187 17.1956 6.47786 17.0326 6.39432 16.8892Z"
                fill="#94A3F7"
            />
            <path
                d="M100.994 27.5777V26.3152C100.994 25.4782 100.662 24.6754 100.07 24.0836C99.4782 23.4917 98.6754 23.1592 97.8384 23.1592H87.3182C86.4811 23.1592 85.6784 23.4917 85.0865 24.0836C84.4946 24.6754 84.1621 25.4782 84.1621 26.3152V36.8355C84.1621 37.6725 84.4946 38.4753 85.0865 39.0672C85.6784 39.659 86.4811 39.9915 87.3182 39.9915H97.8384C98.6754 39.9915 99.4782 39.659 100.07 39.0672C100.662 38.4753 100.994 37.6725 100.994 36.8355V35.5646C100.994 35.3972 100.928 35.2367 100.81 35.1183C100.691 34.9999 100.531 34.9334 100.363 34.9334H98.0488C97.8814 34.9334 97.7208 34.9999 97.6025 35.1183C97.4841 35.2367 97.4176 35.3972 97.4176 35.5646V35.9939C97.4176 36.2729 97.3068 36.5405 97.1095 36.7377C96.9122 36.935 96.6446 37.0459 96.3656 37.0459H88.791C88.512 37.0459 88.2444 36.935 88.0471 36.7377C87.8498 36.5405 87.739 36.2729 87.739 35.9939V27.1569C87.739 26.8779 87.8498 26.6103 88.0471 26.413C88.2444 26.2157 88.512 26.1048 88.791 26.1048H96.3656C96.6446 26.1048 96.9122 26.2157 97.1095 26.413C97.3068 26.6103 97.4176 26.8779 97.4176 27.1569V27.5777C97.4176 27.7451 97.4841 27.9056 97.6025 28.024C97.7208 28.1424 97.8814 28.2089 98.0488 28.2089H100.363C100.531 28.2089 100.691 28.1424 100.81 28.024C100.928 27.9056 100.994 27.7451 100.994 27.5777Z"
                fill="white"
            />
            <path
                d="M37.2422 23.1592H21.6722C21.5048 23.1592 21.3443 23.2257 21.2259 23.3441C21.1075 23.4624 21.041 23.623 21.041 23.7904V25.4736C21.041 25.641 21.1075 25.8016 21.2259 25.92C21.3443 26.0383 21.5048 26.1048 21.6722 26.1048H27.6551V39.3687C27.6551 39.5361 27.7216 39.6967 27.84 39.8151C27.9583 39.9335 28.1189 40 28.2863 40H30.6281C30.7955 40 30.9561 39.9335 31.0744 39.8151C31.1928 39.6967 31.2593 39.5361 31.2593 39.3687V26.1048H37.2422C37.4096 26.1048 37.5701 26.0383 37.6885 25.92C37.8069 25.8016 37.8734 25.641 37.8734 25.4736V23.7904C37.8734 23.623 37.8069 23.4624 37.6885 23.3441C37.5701 23.2257 37.4096 23.1592 37.2422 23.1592Z"
                fill="white"
            />
            <path
                d="M79.3232 23.1592L63.7533 23.1508C63.5859 23.1508 63.4253 23.2173 63.307 23.3356C63.1886 23.454 63.1221 23.6146 63.1221 23.782V39.3603C63.1221 39.5277 63.1886 39.6883 63.307 39.8067C63.4253 39.925 63.5859 39.9915 63.7533 39.9915H79.3232C79.4906 39.9915 79.6512 39.925 79.7696 39.8067C79.8879 39.6883 79.9544 39.5277 79.9544 39.3603V37.6771C79.9544 37.5097 79.8879 37.3491 79.7696 37.2307C79.6512 37.1124 79.4906 37.0459 79.3232 37.0459H66.6989V33.0398H77.2192C77.3866 33.0398 77.5471 32.9733 77.6655 32.8549C77.7839 32.7365 77.8504 32.576 77.8504 32.4086V30.7253C77.8504 30.5579 77.7839 30.3974 77.6655 30.279C77.5471 30.1606 77.3866 30.0941 77.2192 30.0941H66.6989V26.1048H79.3232C79.4906 26.1048 79.6512 26.0383 79.7696 25.92C79.8879 25.8016 79.9544 25.641 79.9544 25.4736V23.7904C79.9544 23.623 79.8879 23.4624 79.7696 23.3441C79.6512 23.2257 79.4906 23.1592 79.3232 23.1592Z"
                fill="white"
            />
            <path
                d="M58.2822 23.1508H55.9678C55.8003 23.1508 55.6398 23.2173 55.5214 23.3356C55.403 23.454 55.3365 23.6146 55.3365 23.782V30.0941H45.6579V23.782C45.6579 23.6146 45.5914 23.454 45.4731 23.3356C45.3547 23.2173 45.1941 23.1508 45.0267 23.1508H42.7123C42.5449 23.1508 42.3843 23.2173 42.2659 23.3356C42.1476 23.454 42.0811 23.6146 42.0811 23.782V39.3519C42.0811 39.5193 42.1476 39.6799 42.2659 39.7982C42.3843 39.9166 42.5449 39.9831 42.7123 39.9831H45.0267C45.1941 39.9831 45.3547 39.9166 45.4731 39.7982C45.5914 39.6799 45.6579 39.5193 45.6579 39.3519V33.0398H55.3365V39.3519C55.3365 39.5193 55.403 39.6799 55.5214 39.7982C55.6398 39.9166 55.8003 39.9831 55.9678 39.9831H58.2822C58.4496 39.9831 58.6102 39.9166 58.7285 39.7982C58.8469 39.6799 58.9134 39.5193 58.9134 39.3519V23.782C58.9134 23.6146 58.8469 23.454 58.7285 23.3356C58.6102 23.2173 58.4496 23.1508 58.2822 23.1508Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_902_14230">
                <rect width={100.994} height={40} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const Memo = memo(SvgLogoDarkColored);
export default Memo;
