import type { Theme as StyledSystemTheme } from 'styled-system';

import breakpoints from '@theme/configs/breakpoints';
import colors from '@theme/configs/colors';
import mediaQueries from '@theme/configs/mediaQueries';
import shadows from '@theme/configs/shadows';
import zIndices from '@theme/configs/zIndices';

export interface Theme extends StyledSystemTheme {
    breakpoints: typeof breakpoints;
    colors: typeof colors;
    mediaQueries: typeof mediaQueries;
    shadows: typeof shadows;
    zIndices: typeof zIndices;
}

const theme: Theme = {
    breakpoints,
    colors,
    mediaQueries,
    shadows,
    zIndices,
};

export default theme;
