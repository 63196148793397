import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgMethods = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={40}
        height={30}
        viewBox="0 0 40 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.12733 28.6673L0 19.0007L2.66667 16.334L10 22.334L30 0.333984L32.6667 1.66732L13.2227 28.4007C12.9926 28.7178 12.6954 28.9803 12.3523 29.1695C12.0092 29.3588 11.6286 29.4701 11.2376 29.4956C10.8466 29.521 10.4548 29.46 10.0901 29.3169C9.72534 29.1737 9.39663 28.9519 9.12733 28.6673Z"
            fill="white"
        />
        <path
            d="M39.3335 9.66732H34.0002C33.8234 9.66732 33.6538 9.59708 33.5288 9.47206C33.4037 9.34703 33.3335 9.17746 33.3335 9.00065C33.3335 8.82384 33.4037 8.65427 33.5288 8.52925C33.6538 8.40422 33.8234 8.33398 34.0002 8.33398H39.3335C39.5103 8.33398 39.6799 8.40422 39.8049 8.52925C39.9299 8.65427 40.0002 8.82384 40.0002 9.00065C40.0002 9.17746 39.9299 9.34703 39.8049 9.47206C39.6799 9.59708 39.5103 9.66732 39.3335 9.66732Z"
            fill="white"
        />
        <path
            d="M39.3332 16.3333H29.3332C29.1564 16.3333 28.9868 16.2631 28.8618 16.1381C28.7367 16.013 28.6665 15.8435 28.6665 15.6667C28.6665 15.4899 28.7367 15.3203 28.8618 15.1953C28.9868 15.0702 29.1564 15 29.3332 15H39.3332C39.51 15 39.6796 15.0702 39.8046 15.1953C39.9296 15.3203 39.9998 15.4899 39.9998 15.6667C39.9998 15.8435 39.9296 16.013 39.8046 16.1381C39.6796 16.2631 39.51 16.3333 39.3332 16.3333Z"
            fill="white"
        />
        <path
            d="M39.3333 23.0013H24.6667C24.4899 23.0013 24.3203 22.9311 24.1953 22.806C24.0702 22.681 24 22.5114 24 22.3346C24 22.1578 24.0702 21.9883 24.1953 21.8632C24.3203 21.7382 24.4899 21.668 24.6667 21.668H39.3333C39.5101 21.668 39.6797 21.7382 39.8047 21.8632C39.9298 21.9883 40 22.1578 40 22.3346C40 22.5114 39.9298 22.681 39.8047 22.806C39.6797 22.9311 39.5101 23.0013 39.3333 23.0013Z"
            fill="white"
        />
        <path
            d="M39.3335 29.6673H20.0002C19.8234 29.6673 19.6538 29.5971 19.5288 29.4721C19.4037 29.347 19.3335 29.1775 19.3335 29.0007C19.3335 28.8238 19.4037 28.6543 19.5288 28.5292C19.6538 28.4042 19.8234 28.334 20.0002 28.334H39.3335C39.5103 28.334 39.6799 28.4042 39.8049 28.5292C39.9299 28.6543 40.0002 28.8238 40.0002 29.0007C40.0002 29.1775 39.9299 29.347 39.8049 29.4721C39.6799 29.5971 39.5103 29.6673 39.3335 29.6673Z"
            fill="white"
        />
    </svg>
);

const Memo = memo(SvgMethods);
export default Memo;
