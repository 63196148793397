import breakpoints from './breakpoints';

const mediaQueries = {
    mobile: `@media all and (min-width: ${breakpoints.mobile})`,
    tabletS: `@media all and (min-width: ${breakpoints.tabletS})`,
    tablet: `@media all and (min-width: ${breakpoints.tablet})`,
    laptopS: `@media all and (min-width: ${breakpoints.laptopS})`,
    laptop: `@media all and (min-width: ${breakpoints.laptop})`,
    desktop: `@media all and (min-width: ${breakpoints.desktop})`,
    desktopL: `@media all and (min-width: ${breakpoints.desktopL})`,
};

export default mediaQueries;
