import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { Swiper } from 'swiper/types';

import { useSwiper } from '@hooks';

import colors from '@theme/configs/colors';

import Box from '@atoms/Box';
import Flex from '@atoms/Flex';
import ArrowLeft from '@atoms/Icons/ArrowLeft';
import ArrowRight from '@atoms/Icons/ArrowRight';

import Slider, { SliderPagination } from '@molecules/Slider';

import ReviewCard from '@templates/Reviews/ReviewCard';

const query = graphql`
    query {
        reviewer1: file(relativePath: { eq: "reviews/reviewer1.jpg" }) {
            childImageSharp {
                gatsbyImageData(aspectRatio: 1.1)
            }
        }
    }
`;

const ReviewSlider = () => {
    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);
    const { reviewer1 } = useStaticQuery(query);
    const { sliderRef, sliderPaginationRef, nextSlide, prevSlide } = useSwiper();

    const handleSlideChange = (swiper: Swiper) => {
        if (swiper.isBeginning !== isStart) setIsStart(swiper.isBeginning);

        if (swiper.isEnd !== isEnd) setIsEnd(swiper.isEnd);
    };

    return (
        <Box width="100%">
            <Slider
                sliderName="review-slider"
                slidesPerView={1}
                spaceBetween={100}
                sliderRef={sliderRef}
                paginationRef={sliderPaginationRef}
                onSlideChange={handleSlideChange}
            >
                {[0, 1, 2, 3, 4].map((item) => (
                    <ReviewCard
                        key={item}
                        authorImg={
                            <GatsbyImage
                                style={{ width: 'auto' }}
                                image={reviewer1.childImageSharp.gatsbyImageData}
                                alt="PATRICIA THOMPSON"
                            />
                        }
                        authorPosition="CROSSFIT ATHLETE"
                        authorName="PATRICIA THOMPSON"
                        reviewText="I was one of those people with a daily workout
                                                    routine. The X°Cryo helped me reduce muscle
                                                    soreness after my workout, enabling me to give
                                                    100% every day."
                    />
                ))}
            </Slider>

            <Box display="flex" alignItems="center" mt="2.6rem" justifyContent="space-between">
                <SliderPagination
                    pl={{ _: '0', tablet: '2.5rem' }}
                    className="swiper-pagination"
                    ref={sliderPaginationRef}
                />
                <Flex gridGap="4.5rem">
                    <Box
                        aria-label="Previous"
                        aria-describedby="Show previous slide"
                        onClick={!isStart ? prevSlide : undefined}
                        opacity={!isStart ? 1 : 0.3}
                        display="flex"
                        height="100%"
                        color={colors.greyDark}
                        className={!isStart ? 'withAction' : undefined}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <ArrowLeft style={{ fill: 'transparent' }} />
                    </Box>
                    <Box
                        aria-label="Next"
                        aria-describedby="Show next slide"
                        onClick={!isEnd ? nextSlide : undefined}
                        opacity={!isEnd ? 1 : 0.3}
                        display="flex"
                        height="100%"
                        className={!isEnd ? 'withAction' : undefined}
                        color={colors.greyDark}
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <ArrowRight style={{ fill: 'transparent' }} />
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default ReviewSlider;
