import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgYouTube = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={24}
        height={18}
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M23.4879 3.18332C23.3518 2.67509 23.0854 2.21118 22.7151 1.83749C22.3447 1.4638 21.8832 1.19327 21.3762 1.05266C19.4795 0.546867 12 0.546875 12 0.546875C12 0.546875 4.49525 0.546867 2.62381 1.05266C2.11477 1.19057 1.65104 1.46009 1.27923 1.83411C0.907424 2.20814 0.640668 2.67348 0.505798 3.18332C1.92192e-06 5.08006 0 8.99998 0 8.99998C0 8.99998 1.92192e-06 12.9325 0.505798 14.8166C0.640668 15.3265 0.907424 15.7918 1.27923 16.1658C1.65104 16.5399 2.11477 16.8094 2.62381 16.9473C4.52054 17.4531 12 17.4531 12 17.4531C12 17.4531 19.5047 17.4531 21.3762 16.9473C21.8855 16.8086 22.3496 16.5389 22.7224 16.1651C23.0951 15.7913 23.3634 15.3264 23.5005 14.8166C24 12.9199 24 8.99998 24 8.99998C24 8.99998 23.9874 5.06741 23.4879 3.18332ZM9.53424 12.5658V5.42147L15.8061 8.98734L9.53424 12.5658Z"
            fill="currentColor"
        />
    </svg>
);

const Memo = memo(SvgYouTube);
export default Memo;
