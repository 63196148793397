export type IColors = 'white' | 'greyCyan' | 'greyLt' | 'grey' | 'greyDark' | 'black' | 'brandBlack' | 'brandAccent' | 'blueDim' | 'redWarning' | 'success';

const colors = {
    white: '#FFFFFF',
    greyCyan: '#F5F6FC',
    greyLt: '#F7F7F9',
    grey: '#EEEFF5',
    greyDark: '#B6B9D6',
    black: '#121521',
    brandBlack: '#101540',
    brandAccent: '#4052F8',
    blueDim: '#2D3AB3',
    redWarning: '#FF003B',
    success: '#15C1A7',
};

export default colors;
