import Box from '@atoms/Box';
import SocialFacebook from '@atoms/Icons/socials/Facebook';
import Instagram from '@atoms/Icons/socials/Instagram';
import LinkedIn from '@atoms/Icons/socials/LinkedIn';
import YouTube from '@atoms/Icons/socials/YouTube';
import SocialLinkWrapper from '@atoms/SocialLinkWrapper';

const SocialLinksBlock = ({ withBoundaries = false }: { withBoundaries?: boolean }) => {
    const maxWidth = withBoundaries ? { _: undefined, tablet: '11rem' } : undefined;

    return (
        <Box
            display="flex"
            gridGap="1rem"
            flexWrap="wrap"
            height="min-content"
            mt={{ _: 'unset', tablet: '1.5rem' }}
            maxWidth={maxWidth}
        >
            <SocialLinkWrapper>
                <SocialFacebook />
            </SocialLinkWrapper>
            <SocialLinkWrapper>
                <Instagram />
            </SocialLinkWrapper>
            <SocialLinkWrapper>
                <YouTube />
            </SocialLinkWrapper>
            <SocialLinkWrapper>
                <LinkedIn />
            </SocialLinkWrapper>
        </Box>
    );
};

export default SocialLinksBlock;
