import {
    FormEvent,
    ForwardedRef,
    forwardRef,
    HTMLInputTypeAttribute,
    ReactElement,
    useState,
} from 'react';
import styled from 'styled-components';

import Box from '@atoms/Box';
import FloatingLabel from '@atoms/Form/FloatingLabel';
import Typography, { fontsConfig } from '@atoms/Typography';

const StyledInput = styled.input<{ hasError: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.grey};
    color: ${({ theme }) => theme.colors.brandBlack};
    display: flex;
    ${{ ...fontsConfig.paragraph2 }};
    flex: 1;
    padding: 2rem 1.25rem 0.8rem 2rem;

    &:focus {
        border-color: ${({ theme }) => theme.colors.brandAccent};
    }

    ${({ hasError, theme }) =>
        hasError &&
        `
    border-color: ${theme.colors.redWarning};
  `};
`;

export interface InputProps {
    name: string;
    value?: string;
    onChange?: (e: FormEvent) => void;
    label?: string | ReactElement;
    type: HTMLInputTypeAttribute;
    placeholder?: string;
    error?: string;
}

const Input = (
    { value, onChange = () => {}, name, label, type, placeholder, error, ...rest }: InputProps,
    ref: ForwardedRef<any>,
) => {
    const [isActive, setIsActive] = useState(false);

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value !== '') {
            setIsActive(true);
        } else {
            setIsActive(false);
        }

        if (typeof onChange === 'function') {
            onChange(e);
        }
    };

    return (
        <FloatingLabel isActive={isActive}>
            <Box as="label" htmlFor={name}>
                {label}
            </Box>
            <Box borderRadius={4}>
                <StyledInput
                    ref={ref}
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    hasError={Boolean(error)}
                    {...rest}
                />
            </Box>
            {Boolean(error) && (
                <Typography variant="caption" color="redWarning" mt="0.5rem">
                    {error}
                </Typography>
            )}
        </FloatingLabel>
    );
};

export default forwardRef(Input);
