import { cloneElement, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import colors from '@theme/configs/colors';
import shadows from '@theme/configs/shadows';

import Box from '@atoms/Box';
import Flex from '@atoms/Flex';
import GridChild from '@atoms/GridChild';
import GridParent from '@atoms/GridParent';
import { MethodsIcon, SolutionsIcon, SpecialistsIcon } from '@atoms/Icons';
import Typography from '@atoms/Typography';

const Column = ({ caption, text, icon }: { caption: string; text: string; icon: ReactElement }) => (
    <Flex
        flex={1}
        p={{
            _: '1.5rem 2rem 4.85rem',
            tablet: '2.5rem 3rem 7.85rem',
            laptopS: '2.5rem 3rem 4rem',
        }}
    >
        <Box flexGrow={1} mr="1.5rem">
            <Typography variant={{ _: 'captionSmall', tablet: 'caption' }} color="blueDim">
                {caption}
            </Typography>
            <Typography variant="h3" as="p">
                {text}
            </Typography>
        </Box>
        <div>
            <Box
                width={{ _: '4rem', tablet: '6rem' }}
                height={{ _: '4rem', tablet: '6rem' }}
                borderRadius="50%"
                backgroundColor={colors.brandAccent}
                color={colors.white}
            >
                {cloneElement(icon, {
                    height: '100%',
                    width: '100%',
                    style: { padding: '1rem' },
                })}
            </Box>
        </div>
    </Flex>
);

const Pros = () => {
    const { t } = useTranslation();

    return (
        <section id="pros-points">
            <GridParent mt={{ _: '6rem', tablet: '12rem' }}>
                <GridChild gridColumn={{ _: '1/13', desktop: '3/ 11' }}>
                    <Box backgroundColor={colors.white} boxShadow={shadows.dropShadow}>
                        <Flex flexDirection={{ _: 'column', laptopS: 'row' }}>
                            <Column
                                caption={t('points.methods.heading')}
                                text={t('points.methods.caption')}
                                icon={<MethodsIcon />}
                            />
                            <Column
                                caption={t('points.specialists.heading')}
                                text={t('points.specialists.caption')}
                                icon={<SpecialistsIcon />}
                            />
                            <Column
                                caption={t('points.solutions.heading')}
                                text={t('points.solutions.caption')}
                                icon={<SolutionsIcon />}
                            />
                        </Flex>
                    </Box>
                </GridChild>
            </GridParent>
        </section>
    );
};

export default Pros;
