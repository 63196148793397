import { phoneNumber } from '@utils/appConfig';
import { useTranslation } from 'react-i18next';

import Button from '@atoms/Button';
import { SmartphoneIcon } from '@atoms/Icons';
import Typography from '@atoms/Typography';

const CallUsBtn = () => {
    const { t } = useTranslation();

    return (
        <Button
            as="a"
            display="inline-block"
            variant="secondary"
            href={`tel:${phoneNumber}`}
            variantTheme="dark"
        >
            <Typography variant="accent" color="white">
                {t('form.callUs')} <SmartphoneIcon style={{ marginLeft: '1.4rem' }} />
            </Typography>
        </Button>
    );
};

export default CallUsBtn;
