import { useTranslation } from 'react-i18next';

import colors from '@theme/configs/colors';

import Box from '@atoms/Box';
import GridChild from '@atoms/GridChild';
import GridParent from '@atoms/GridParent';
import Typography from '@atoms/Typography';

import ParagraphDots from '@molecules/ParagraphDots';

import ReviewSlider from '@templates/Reviews/ReviewSlider';

const Reviews = () => {
    const { t } = useTranslation();

    return (
        <section id="reviews">
            <GridParent>
                <GridChild gridColumn={{ _: '1/ 13', laptop: '4/10', desktopL: '5/9' }}>
                    <Box
                        mt="-10rem"
                        zIndex={99}
                        position="relative"
                        backgroundColor={colors.black}
                        p={{ _: '1.5rem 2rem 3rem', tablet: '3rem 6rem 5rem' }}
                    >
                        <Box maxWidth="59rem" mb="7rem">
                            <ParagraphDots>
                                <Typography variant="h1" as="h2" color="grey">
                                    {t('family.heading')}
                                </Typography>
                            </ParagraphDots>
                        </Box>
                        <ReviewSlider />
                    </Box>
                </GridChild>
            </GridParent>
        </section>
    );
};

export default Reviews;
