import styled from 'styled-components';

import { useHeaderContext } from '@hooks/useHeaderContext';

import colors from '@theme/configs/colors';

const StyledBurger = styled.button<{ isOpen: boolean; color: string }>`
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 2.6rem;
    justify-content: space-around;
    left: 0;
    position: absolute;
    top: 0.7rem;
    width: 4rem;
    z-index: ${({ theme }) => theme.zIndices.burgerMenu + 1};

    &:focus {
        outline: none;
    }

    div {
        background: ${({ isOpen, theme, color }) => (isOpen ? theme.colors.greyDark : color)};
        border-radius: 10px;
        height: 0.4rem;
        position: relative;
        transform-origin: 0.9rem;
        transition: all 0.3s linear;
        width: 4rem;

        :first-child {
            transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
            transform: ${({ isOpen }) => (isOpen ? 'translateX(4rem)' : 'translateX(0.7rem)')};
            width: 3.3rem;
        }

        :nth-child(3) {
            transform: ${({ isOpen }) =>
                isOpen ? 'rotate(-45deg)' : 'rotate(0) translateX(1.3rem)'};
            width: ${({ isOpen }) => (isOpen ? '4rem' : '2.7rem')};
        }
    }
`;

const BurgerButton = ({ isOpen, toggleIsOpen }: { isOpen: boolean; toggleIsOpen: () => void }) => {
    const { hasWhiteBg } = useHeaderContext();

    return (
        <StyledBurger
            color={hasWhiteBg ? colors.greyDark : colors.white}
            isOpen={isOpen}
            onClick={toggleIsOpen}
        >
            <div />
            <div />
            <div />
        </StyledBurger>
    );
};

export default BurgerButton;
